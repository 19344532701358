/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { AppContext } from '../AppContext';
import { useHistory } from 'react-router-dom';

const LaunchPage = () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();

  React.useEffect(() => {
    const init = async () => {
      await appCtx.redirect();
      history.push(appCtx.homePage);
    };

    init();
  }, []);
  return <></>;
};

const LoginPage = () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  const login = async (
    username: string,
    password: string,
    rememberMe: string,
  ) => {
    const login = await appCtx.login(username, password, rememberMe);
    if (login) history.push(appCtx.homePage);
  };

  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [rememberMe, setRememberMe] = React.useState<string>('');

  return (
    <section className="flex flex-row items-center h-screen sm:flex-col">
      <div className="w-2/3 h-screen bg-indigo-600 sm:w-full sm:hidden md:w-1/2">
        <img
          src="assets/ycc-login.jpeg"
          alt=""
          className="object-cover w-full h-full"
        />
      </div>

      <div className="flex items-center justify-center w-1/3 h-screen px-12 mx-0 bg-white sm:px-6 sm:w-full sm:max-w-full ">
        <div className="w-full h-100">
          <h1 className="mt-12 text-2xl font-bold leading-tight sm:text-xl">
            緣溪行露營區後台系統
          </h1>

          <form className="mt-6">
            <div>
              <label className="block text-gray-700">帳號</label>
              <input
                type="email"
                name=""
                id=""
                placeholder="請輸入帳號"
                className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-blue-500 focus:bg-white focus:outline-none"
                autoFocus
                required
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <label className="block text-gray-700">密碼</label>
              <input
                type="password"
                name=""
                id=""
                placeholder="請輸入密碼"
                className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-blue-500 focus:bg-white focus:outline-none"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <label className="flex items-start justify-start mt-5">
              <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 mr-2 bg-white border-2 border-gray-400 rounded focus-within:border-blue-500">
                <input
                  type="checkbox"
                  className="absolute"
                  onChange={(e) => setRememberMe(e.target.value)}
                />
                <svg
                  className="hidden w-4 h-4 text-green-500 pointer-events-none fill-current"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
              <div className="select-none">保持登入</div>
            </label>
          </form>

          <button
            className="block w-full px-4 py-3 mt-6 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-400 focus:bg-indigo-400"
            onClick={() => login(username, password, rememberMe)}
          >
            登入
          </button>

          <hr className="w-full my-6 border-gray-300" />
        </div>
      </div>
    </section>
  );
};

const NotFoundPage = () => {
  return <></>;
};

export { LaunchPage, LoginPage, NotFoundPage };
