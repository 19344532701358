/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import { AppContext } from '../../AppContext';
import { DangerButton } from '../../components/DangerButton';
import { AccountingDocument } from './types';

const AccountingDocumentsPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<AccountingDocument[]>([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const [startDate, setStartDate] = React.useState<moment.Moment | null>();
  const [endDate, setEndDate] = React.useState<moment.Moment | null>();
  const [form] = antd.Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const pageSize = 10;

  const init = async (
    page = currentPage,
    startDate: moment.Moment | null = null,
    endDate: moment.Moment | null = null,
  ) => {
    console.log(startDate, endDate);
    setSpinning(true);
    const pagination = {
      sort: 'date:desc',
      pagination: { page, pageSize },
      filters:
        startDate && endDate
          ? {
              $and: [
                {
                  date: {
                    $gte: startDate.format('YYYY-MM-DD'),
                  },
                },
                {
                  date: {
                    $lte: endDate.format('YYYY-MM-DD'),
                  },
                },
              ],
            }
          : undefined,
      populate: 'subpoena_details',
    };
    const result = await appCtx.fetch(
      'get',
      '/api/subpoenas?' + qs.stringify(pagination),
    );
    if (result) {
      const dataWithKeys = result.data.map((ad: any) => ({
        key: ad.id,
        code: ad.attributes.Code,
        date: ad.attributes.date,
        debtor: ad.attributes.subpoena_details.data.reduce(
          (acc: number, obj: any) => +obj.attributes.debit + acc,
          0,
        ),
        lender: ad.attributes.subpoena_details.data.reduce(
          (acc: number, obj: any) => +obj.attributes.lender + acc,
          0,
        ),
      }));
      setDataSource(dataWithKeys);
      setTotal(result.meta.pagination.total);
      setSpinning(false);
    }
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  const columns: ColumnsType<AccountingDocument> = [
    {
      title: '日期',
      align: 'left',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '借方',
      align: 'center',
      dataIndex: 'debtor',
      key: 'debtor',
    },
    {
      title: '貸方',
      align: 'center',
      dataIndex: 'lender',
      key: 'lender',
    },
    {
      title: '編號',
      align: 'left',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '操作',
      align: 'center',
      width: 180,
      render: (item) => {
        return (
          <>
            <antd.Button
              className="mr-3"
              type="primary"
              onClick={() => history.push(`${location.pathname}/${item.key}`)}
            >
              修改
            </antd.Button>
            <DangerButton
              title="移除"
              message="確定要刪除傳票?"
              onClick={async () => {
                const result = await appCtx.fetch(
                  'delete',
                  `/api/subpoenas/${item.key}`,
                );
                init(currentPage);
              }}
            />
          </>
        );
      },
    },
  ];

  function disabledEndDate(current: moment.Moment) {
    // return current && current < moment().endOf('day');
    let localStartDate = moment('1800');
    if (!startDate) return current && current < localStartDate.startOf('day');

    return current && current < startDate.startOf('day');
  }

  function disabledStartDate(current: moment.Moment) {
    // return current && current < moment().endOf('day');
    let localEndDate = moment('2200');
    if (!endDate) return current && current > localEndDate.endOf('day');

    return current && current > endDate.endOf('day');
  }

  function onStartDateChange(date: moment.Moment | null, dateString: string) {
    setStartDate(date);
  }

  function onEndDateChange(date: moment.Moment | null, dateString: string) {
    setEndDate(date);
  }

  return (
    <antd.Spin spinning={spinning}>
      <div>
        <antd.Button
          className="px-large"
          onClick={() => history.push(`${location.pathname}/new`)}
        >
          新增傳票
        </antd.Button>
        <antd.Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          className="mb-8"
          onFinish={(values) => {
            init(1, startDate, endDate);
            // form.resetFields();
            // setStartDate(null);
            // setEndDate(null);
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            setTimeout(() => {
              const arr = errorFields.map((field) => ({
                name: field.name,
                errors: [],
              }));
              form.setFields(arr);
            }, 2000);
          }}
        >
          <div className="flex justify-center gap-3 my-3">
            <antd.Form.Item label="起始日期" name="startDate" className="mb-0">
              <antd.DatePicker
                onChange={onStartDateChange}
                disabledDate={disabledStartDate}
              />
            </antd.Form.Item>
            <antd.Form.Item label="結束日期" name="endDate" className="mb-0">
              <antd.DatePicker
                disabledDate={disabledEndDate}
                onChange={onEndDateChange}
              />
            </antd.Form.Item>
          </div>

          <antd.Button className="block mx-auto px-large" htmlType="submit">
            搜尋
          </antd.Button>
        </antd.Form>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => {
            init(page);
            setCurrentPage(page);
          },
        }}
      />
    </antd.Spin>
  );
};

export { AccountingDocumentsPage };
