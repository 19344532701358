export interface AccountingDocument {
  date: string;
  debtor: number;
  lender: number;
  code: string;
  key: number;
}

export interface Vendor {
  key: number;
  name: string;
  uniformNumbers: string;
  contactPerson1: string;
  phone: string;
  shortName: string;
  email: string;
  contactPerson2: string;
  bankCode: string;
  fax: string;
  bankAccount: string;
  address: string;
  hide: boolean;
  note: string;
}

export interface Grade {
  key: number;
  code: string;
  name: string;
  isSubGrade?: boolean;
}

export interface SubGrade {
  key: number;
  code: string;
  name: string;
}

export interface MapOfSubGrades {
  [key: string | number]: SubGrade[];
}

export interface AccountingDocumentDetail {
  key: number;
  codeId?: number;
  code?: string;
  codeName?: string;
  vendor?: string;
  vendorId?: number;
  summary?: string;
  lender?: number;
  debtor?: number;
  isSubGrade?: boolean;
  parentDate?: string;
  parentId?: number;
}

export const dateFormat = 'YYYY-MM-DD';

// export const emptyAccountingDocumentDetail = {
//   key: 0,
//   gradeCode: '',
//   gradeName: '',
//   vendor: '',
//   summary: '',
//   lender: '',
//   debtor: '',
// };

export const emptyGrade = {
  key: 0,
  code: '',
  name: '',
};

export const emptySubGrade = {
  key: 0,
  code: '',
  name: '',
};

export const emptyVendor = {
  name: '',
  uniformNumbers: '',
  contactPerson1: '',
  phone: '',
  shortName: '',
  email: '',
  contactPerson2: '',
  bankCode: '',
  fax: '',
  bankAccount: '',
  address: '',
  hide: false,
  note: '',
};
