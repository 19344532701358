/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as antd from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

interface Room {
  id?: number;
  name: string;
  status: string;
}

const toBase64 = (file: any) =>
  new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const uploadImageCallBack = (file: any) => {
  return new Promise<any>((resolve, reject) => {
    toBase64(file)
      .then((data) => resolve({ data: { link: data } }))
      .catch((error) => reject(error));
  });
};

const content_1_label = 'equipment';
const content_2_label = 'notice';
const content_3_label = 'holidayJudgment';

function cleanText(str: string) {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}

export const AERoomCollection = ({
  item,
  onSuccess,
  equipments,
}: {
  item?: any;
  onSuccess: () => void;
  equipments: any[];
}) => {
  const appCtx = React.useContext(AppContext);
  const formRef = React.useRef<antd.FormInstance>(null);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [content1, setContent1] = React.useState<any>();
  const [content2, setContent2] = React.useState<any>();
  const [content3, setContent3] = React.useState<any>();
  const handleChange = ({ fileList }: any) => setFileList(fileList.slice(0, 5));
  const [rooms, setRooms] = React.useState<Room[]>([]);
  const delRooms = (index: number) => {
    // setRooms((prev) => prev.filter((_, i) => i !== index));
  };

  React.useEffect(() => {
    item?.images?.data &&
      setFileList(
        item.images.data.map((item: any) => {
          return { id: item.id, url: item.attributes.url };
        }),
      );

    item?.rooms?.data &&
      setRooms(
        item.rooms.data.map((item: any) => {
          return {
            id: item.id,
            name: item.attributes.name,
            status: item.attributes.status,
          };
        }),
      );

    const eqitem = item
      ? item.rental_equipments.data.map((item: any) => item.id)
      : [];
    eqitem.map((item: any) =>
      formRef.current?.setFieldsValue({ [`eq_${item}`]: true }),
    );

    if (item?.[content_1_label]) {
      const contentBlock = htmlToDraft(item[content_1_label]);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setContent1(editorState);
      }
    }
    if (item?.[content_2_label]) {
      const contentBlock = htmlToDraft(item[content_2_label]);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setContent2(editorState);
      }
    }
    if (item?.[content_3_label]) {
      const contentBlock = htmlToDraft(item[content_3_label]);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setContent3(editorState);
      }
    }
  }, []);

  const onFinish = async (values: any) => {
    const roomItems = rooms.filter((item) => item.name !== '');
    if (roomItems.filter((item) => !item.status).length > 0) {
      Notification.add('error', '請為每個房間選擇狀態');
      return;
    }
    console.log(values);
    // return;
    const k = Object.keys(values).filter((item) => item.startsWith('eq_'));
    const equipments = k
      .filter((item) => values[item])
      .map((item) => +item.split('_')[1]);

    if (fileList.length === 0) {
      Notification.add('error', '請至少選擇一張圖片');
      return;
    }

    setSpinning(true);

    const ids = await appCtx.upload(fileList.filter((item) => !item.id));
    if (!ids) {
      Notification.add('error', '上傳圖片失敗');
      return;
    }

    let data: any = null;

    if (item?.id) {
      const content1Raw = convertToRaw(content1.getCurrentContent());
      const content2Raw = convertToRaw(content2.getCurrentContent());
      const content3Raw = convertToRaw(content3.getCurrentContent());
      const content1Cleaned: any = {
        ...content1Raw,
        blocks: content1Raw.blocks.map((b) => ({
          ...b,
          text: cleanText(b.text),
        })),
      };
      const content2Cleaned: any = {
        ...content2Raw,
        blocks: content2Raw.blocks.map((b) => ({
          ...b,
          text: cleanText(b.text),
        })),
      };
      const content3Cleaned: any = {
        ...content3Raw,
        blocks: content3Raw.blocks.map((b) => ({
          ...b,
          text: cleanText(b.text),
        })),
      };
      data = await appCtx.fetch('put', '/api/room-collections/' + item.id, {
        data: {
          name: values.name,
          [content_1_label]: draftToHtml(content1Cleaned),
          [content_2_label]: draftToHtml(content2Cleaned),
          [content_3_label]: draftToHtml(content3Cleaned),
          count: values.count,
          images: fileList
            .filter((item) => item.id)
            .map((item) => item.id)
            .concat(ids),
          maxCount: values.maxCount,
          checkinTime: moment(values.checkinTime).format('HH:mm:00'),
          checkoutTime: moment(values.checkoutTime).format('HH:mm:00'),
          intro: values.intro,
          shampoo: values.shampoo,
          bathTowel: values.bathTowel,
          bodyWash: values.bodyWash,
          babyBath: values.babyBath,
          towel: values.towel,
          toothpaste: values.toothpaste,
          toothbrush: values.toothbrush,
          hairDryer: values.hairDryer,
          airConditioner: values.airConditioner,
          thermos: values.thermos,
          refrigerator: values.refrigerator,
          socket: values.socket,
          slippers: values.slippers,
          extraQuilt: values.extraQuilt,
          extraPillow: values.extraPillow,
          pillowAndQuilt: values.pillowAndQuilt,
          parkingFree: values.parkingFree,
          electricCarParkingFree: values.electricCarParkingFree,
          electricCarParkingPaid: values.electricCarParkingPaid,
          wirelessNetwork: values.wirelessNetwork,
          breakfastFree: values.breakfastFree,
          breakfastPaid: values.breakfastPaid,
          lunchFree: values.lunchFree,
          lunchPaid: values.lunchPaid,
          afternoonTeaFree: values.afternoonTeaFree,
          afternoonTeaPaid: values.afternoonTeaPaid,
          dinner: values.dinner,
          dinnerPaid: values.dinnerPaid,
          canopy: values.canopy,
          barbecueEquipment: values.barbecueEquipment,
          sand: values.sand,
          paddlingPool: values.paddlingPool,
          archeryRange: values.archeryRange,
          playArea: values.playArea,
          badmintonCourt: values.badmintonCourt,
          volleyballCourt: values.volleyballCourt,
          restArea: values.restArea,
          picnicArea: values.picnicArea,
          landscapeArea: values.landscapeArea,
          rental_equipments: equipments,
          roomItems,
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/room-collections', {
        data: {
          name: values.name,
          [content_1_label]: draftToHtml(
            convertToRaw(content1.getCurrentContent()),
          ),
          [content_2_label]: draftToHtml(
            convertToRaw(content2.getCurrentContent()),
          ),
          [content_3_label]: draftToHtml(
            convertToRaw(content3.getCurrentContent()),
          ),
          count: values.count,
          images: ids,
          maxCount: values.maxCount,
          checkinTime: moment(values.checkinTime).format('HH:mm:00'),
          checkoutTime: moment(values.checkoutTime).format('HH:mm:00'),
          intro: values.intro,
          shampoo: values.shampoo,
          bathTowel: values.bathTowel,
          bodyWash: values.bodyWash,
          babyBath: values.babyBath,
          towel: values.towel,
          toothpaste: values.toothpaste,
          toothbrush: values.toothbrush,
          hairDryer: values.hairDryer,
          airConditioner: values.airConditioner,
          thermos: values.thermos,
          refrigerator: values.refrigerator,
          socket: values.socket,
          slippers: values.slippers,
          extraQuilt: values.extraQuilt,
          extraPillow: values.extraPillow,
          pillowAndQuilt: values.pillowAndQuilt,
          parkingFree: values.parkingFree,
          electricCarParkingFree: values.electricCarParkingFree,
          electricCarParkingPaid: values.electricCarParkingPaid,
          wirelessNetwork: values.wirelessNetwork,
          breakfastFree: values.breakfastFree,
          breakfastPaid: values.breakfastPaid,
          lunchFree: values.lunchFree,
          lunchPaid: values.lunchPaid,
          afternoonTeaFree: values.afternoonTeaFree,
          afternoonTeaPaid: values.afternoonTeaPaid,
          dinnerFree: values.dinnerFree,
          dinnerPaid: values.dinnerPaid,
          canopy: values.canopy,
          barbecueEquipment: values.barbecueEquipment,
          sand: values.sand,
          paddlingPool: values.paddlingPool,
          archeryRange: values.archeryRange,
          playArea: values.playArea,
          badmintonCourt: values.badmintonCourt,
          volleyballCourt: values.volleyballCourt,
          restArea: values.restArea,
          picnicArea: values.picnicArea,
          landscapeArea: values.landscapeArea,
          rental_equipments: equipments,
        },
      });
      await appCtx.fetch('put', '/api/room-collections/' + data.data.id, {
        data: { roomItems },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.id ? '編輯' : '新增') + '房型設定成功',
      );
      onSuccess();
    } else {
      await appCtx.deUpload(ids as any);
    }
    setSpinning(false);
  };

  return (
    <antd.Spin spinning={spinning}>
      <antd.Form
        ref={formRef}
        initialValues={{
          name: item?.name,
          content1: item?.[content_1_label],
          content2: item?.[content_2_label],
          content3: item?.[content_3_label],
          count: item?.count,
          images: item?.images,
          maxCount: item?.maxCount,
          checkinTime: item
            ? moment(`2000-01-01T${item.checkinTime}`)
            : undefined,
          checkoutTime: item
            ? moment(`2000-01-01T${item.checkoutTime}`)
            : undefined,
          intro: item?.intro,
          shampoo: item?.shampoo,
          bathTowel: item?.bathTowel,
          bodyWash: item?.bodyWash,
          babyBath: item?.babyBath,
          towel: item?.towel,
          toothpaste: item?.toothpaste,
          toothbrush: item?.toothbrush,
          hairDryer: item?.hairDryer,
          airConditioner: item?.airConditioner,
          thermos: item?.thermos,
          refrigerator: item?.refrigerator,
          socket: item?.socket,
          slippers: item?.slippers,
          extraQuilt: item?.extraQuilt,
          extraPillow: item?.extraPillow,
          pillowAndQuilt: item?.pillowAndQuilt,
          parkingFree: item?.parkingFree,
          electricCarParkingFree: item?.electricCarParkingFree,
          electricCarParkingPaid: item?.electricCarParkingPaid,
          wirelessNetwork: item?.wirelessNetwork,
          breakfastFree: item?.breakfastFree,
          breakfastPaid: item?.breakfastPaid,
          lunchFree: item?.lunchFree,
          lunchPaid: item?.lunchPaid,
          afternoonTeaFree: item?.afternoonTeaFree,
          afternoonTeaPaid: item?.afternoonTeaPaid,
          dinnerFree: item?.dinnerFree,
          dinnerPaid: item?.dinnerPaid,
          canopy: item?.canopy,
          barbecueEquipment: item?.barbecueEquipment,
          sand: item?.sand,
          paddlingPool: item?.paddlingPool,
          archeryRange: item?.archeryRange,
          playArea: item?.playArea,
          badmintonCourt: item?.badmintonCourt,
          volleyballCourt: item?.volleyballCourt,
          restArea: item?.restArea,
          picnicArea: item?.picnicArea,
          landscapeArea: item?.landscapeArea,
          rental_equipments: item?.rental_equipments,
          rooms: item?.rooms,
        }}
        onFinish={onFinish}
      >
        <h5 className="font-weight-bold mb-4">{item ? '編輯' : '新增'}</h5>

        <antd.Form.Item
          name="name"
          label="房型名稱"
          rules={[{ required: true, message: '請輸入房型名稱' }]}
        >
          <antd.Input placeholder="請輸入房型名稱" />
        </antd.Form.Item>

        <antd.Form.Item
          name="count"
          label="正常容納人數"
          rules={[{ required: true, message: '請輸入正常容納人數' }]}
        >
          <antd.InputNumber placeholder="請輸入正常容納人數" />
        </antd.Form.Item>

        <antd.Form.Item
          name="maxCount"
          label="加床上限人數"
          rules={[{ required: true, message: '請輸入加床上限人數' }]}
        >
          <antd.InputNumber placeholder="請輸入加床上限人數" />
        </antd.Form.Item>

        <antd.Form.Item
          name="checkinTime"
          label="入住時間"
          rules={[{ required: true, message: '請輸入入住時間' }]}
        >
          <antd.TimePicker format="HH:mm" placeholder="請輸入入住時間" />
        </antd.Form.Item>

        <antd.Form.Item
          name="checkoutTime"
          label="退房時間"
          rules={[{ required: true, message: '請輸入退房時間' }]}
        >
          <antd.TimePicker format="HH:mm" placeholder="請輸入退房時間" />
        </antd.Form.Item>

        <antd.Form.Item
          name="intro"
          label="房間介紹"
          rules={[{ required: true, message: '請輸入房間介紹' }]}
        >
          <antd.Input.TextArea rows={4} placeholder="請輸入房間介紹" />
        </antd.Form.Item>

        <antd.Form.Item
          name="content1"
          label="帳內設備"
          rules={[{ required: true, message: '請輸入帳內設備' }]}
          className="grid"
          labelAlign="left"
        >
          <div className="bg-gray-100">
            <Editor
              editorState={content1}
              onEditorStateChange={(state) => {
                setContent1(state);
                formRef.current!.setFieldsValue({
                  content1: convertToRaw(
                    content1.getCurrentContent(),
                  ).blocks.reduce((acc, val) => acc + val.text, ''),
                });
              }}
              localization={{ locale: 'zh_tw' }}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  //   alt: { present: true, mandatory: true },
                  previewImage: true,
                  defaultSize: { width: 'auto', height: 'auto' },
                },
              }}
            />
          </div>
        </antd.Form.Item>
        <antd.Form.Item
          name="content2"
          label="住宿須知"
          rules={[{ required: true, message: '請輸入住宿須知' }]}
          className="grid"
          labelAlign="left"
        >
          <div className="bg-gray-100">
            <Editor
              editorState={content2}
              onEditorStateChange={(state) => {
                setContent2(state);

                formRef.current!.setFieldsValue({
                  content2: convertToRaw(
                    content2.getCurrentContent(),
                  ).blocks.reduce((acc, val) => acc + val.text, ''),
                });
              }}
              localization={{ locale: 'zh_tw' }}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  //   alt: { present: true, mandatory: true },
                  previewImage: true,
                  defaultSize: { width: 'auto', height: 'auto' },
                },
              }}
            />
          </div>
        </antd.Form.Item>
        <antd.Form.Item
          name="content3"
          label="平/假日判定"
          rules={[{ required: true, message: '請輸入平/假日判定' }]}
          className="grid"
          labelAlign="left"
        >
          <div className="bg-gray-100">
            <Editor
              editorState={content3}
              onEditorStateChange={(state) => {
                setContent3(state);

                formRef.current!.setFieldsValue({
                  content3: convertToRaw(
                    content3.getCurrentContent(),
                  ).blocks.reduce((acc, val) => acc + val.text, ''),
                });
              }}
              localization={{ locale: 'zh_tw' }}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  //   alt: { present: true, mandatory: true },
                  previewImage: true,
                  defaultSize: { width: 'auto', height: 'auto' },
                },
              }}
            />
          </div>
        </antd.Form.Item>

        <div className="flex justify-start mb-4">
          <antd.Button
            type="primary"
            onClick={() =>
              setRooms((prev) => [...prev, { name: '', status: '' }])
            }
          >
            新增房間
          </antd.Button>
        </div>

        {rooms.map((item, index) => (
          <antd.Form.Item name={`room_${index}`}>
            <antd.Input.Group compact>
              <antd.Input
                style={{ width: 'calc(100% - 250px)' }}
                placeholder="請輸入房間名稱"
                onChange={(e) => {
                  setRooms((prev) => {
                    prev[index] = { ...prev[index], name: e.target.value };
                    return [...prev];
                  });
                }}
                value={rooms[index].name}
              />
              <antd.Button danger onClick={() => delRooms(index)}>
                刪除
              </antd.Button>
              <div className="ml-6" />
              <antd.Radio.Group
                defaultValue={rooms[index].status}
                onChange={(e) => {
                  setRooms((prev) => {
                    prev[index] = { ...prev[index], status: e.target.value };
                    return [...prev];
                  });
                }}
              >
                <antd.Radio value={'maintaining'}>維護中</antd.Radio>
                <antd.Radio value={'normal'}>正常</antd.Radio>
              </antd.Radio.Group>
            </antd.Input.Group>
          </antd.Form.Item>
        ))}

        <antd.Upload
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          multiple={true}
          showUploadList={{ showPreviewIcon: false }}
          accept={'.bmp,.jpg,.jpeg,.png,.gif'}
        >
          {fileList.length >= 5 ? null : (
            <>
              <PlusOutlined />
              <div>{`還可上傳${5 - fileList.length}張`}</div>
            </>
          )}
        </antd.Upload>

        <p className="mb-4">{'設備與服務: '}</p>

        <div className="grid grid-cols-5 ml-16">
          <p className="mb-2 col-span-5">{'衛浴用品'}</p>
          <antd.Form.Item name="shampoo" className="!mb-0">
            <CustomCheckbox label="洗髮乳" />
          </antd.Form.Item>

          <antd.Form.Item name="bathTowel" className="!mb-0">
            <CustomCheckbox label="浴巾" />
          </antd.Form.Item>
          <antd.Form.Item name="bodyWash" className="!mb-0">
            <CustomCheckbox label="沐浴精" />
          </antd.Form.Item>
          <antd.Form.Item name="babyBath" className="!mb-0">
            <CustomCheckbox label="嬰兒澡盆" />
          </antd.Form.Item>
          <antd.Form.Item name="towel" className="!mb-0">
            <CustomCheckbox label="毛巾" />
          </antd.Form.Item>
          <antd.Form.Item name="toothpaste" className="!mb-0">
            <CustomCheckbox label="牙膏" />
          </antd.Form.Item>
          <antd.Form.Item name="toothbrush" className="!mb-0">
            <CustomCheckbox label="牙刷" />
          </antd.Form.Item>
          <antd.Form.Item name="hairDryer" className="!mb-0">
            <CustomCheckbox label="吹風機" />
          </antd.Form.Item>

          <p className="mt-5 mb-2 col-span-5">{'電器用品'}</p>
          <antd.Form.Item name="airConditioner" className="!mb-0">
            <CustomCheckbox label="冷氣" />
          </antd.Form.Item>

          <antd.Form.Item name="thermos" className="!mb-0">
            <CustomCheckbox label="保溫瓶" />
          </antd.Form.Item>

          <antd.Form.Item name="refrigerator" className="!mb-0">
            <CustomCheckbox label="共用冰箱" />
          </antd.Form.Item>

          <p className="mt-5 mb-2 col-span-5">{'寢具設備'}</p>
          <antd.Form.Item name="socket" className="!mb-0">
            <CustomCheckbox label="插座(110v)" />
          </antd.Form.Item>

          <antd.Form.Item name="slippers" className="!mb-0">
            <CustomCheckbox label="拖鞋" />
          </antd.Form.Item>

          <antd.Form.Item name="extraQuilt" className="!mb-0">
            <CustomCheckbox label="額外棉被" />
          </antd.Form.Item>

          <antd.Form.Item name="extraPillow" className="!mb-0">
            <CustomCheckbox label="額外枕頭" />
          </antd.Form.Item>

          <antd.Form.Item name="pillowAndQuilt" className="!mb-0">
            <CustomCheckbox label="枕頭/棉被" />
          </antd.Form.Item>

          <p className="mt-5 mb-2 col-span-5">{'停車設施'}</p>
          <antd.Form.Item name="parkingFree" className="!mb-0">
            <CustomCheckbox label="停車場(免費)" />
          </antd.Form.Item>

          <antd.Form.Item
            name="electricCarParkingFree"
            className="!mb-0 col-span-2"
          >
            <CustomCheckbox label="電動車專用車位" />
          </antd.Form.Item>

          <antd.Form.Item
            className="!mb-0 col-span-5"
            name="electricCarParkingPaid"
          >
            <CustomCheckbox label="電動車專用車位(付費)" />
          </antd.Form.Item>

          <p className="mt-5 mb-2 col-span-5">{'網路設施'}</p>
          <antd.Form.Item name="wirelessNetwork" className="!mb-0">
            <CustomCheckbox label="無線網路(免費)" />
          </antd.Form.Item>
        </div>

        <div className="grid grid-cols-2 ml-16">
          <p className="mt-5 mb-2 col-span-2">{'餐點服務'}</p>

          <antd.Form.Item name="breakfastFree" className="!mb-0">
            <CustomCheckbox label="早餐(免費)" />
          </antd.Form.Item>

          <antd.Form.Item name="breakfastPaid" className="!mb-0">
            <CustomCheckbox label="早餐(付費)" />
          </antd.Form.Item>

          <antd.Form.Item name="lunchFree" className="!mb-0">
            <CustomCheckbox label="午餐(免費)" />
          </antd.Form.Item>

          <antd.Form.Item name="lunchPaid" className="!mb-0">
            <CustomCheckbox label="早餐(付費)" />
          </antd.Form.Item>

          <antd.Form.Item name="afternoonTeaFree" className="!mb-0">
            <CustomCheckbox label="下午茶(免費)" />
          </antd.Form.Item>

          <antd.Form.Item name="afternoonTeaPaid" className="!mb-0">
            <CustomCheckbox label="下午茶(付費)" />
          </antd.Form.Item>

          <antd.Form.Item name="dinnerFree" className="!mb-0">
            <CustomCheckbox label="晚餐(免費)" />
          </antd.Form.Item>

          <antd.Form.Item name="dinnerPaid" className="!mb-0">
            <CustomCheckbox label="晚餐(付費)" />
          </antd.Form.Item>

          <p className="mt-5 mb-2 col-span-2">{'廚藝設備'}</p>
          <antd.Form.Item name="canopy" className="!mb-0">
            <CustomCheckbox label="炊事帳" />
          </antd.Form.Item>

          <antd.Form.Item name="barbecueEquipment" className="!mb-0">
            <CustomCheckbox label="烤肉設備" />
          </antd.Form.Item>
        </div>

        <div className="grid grid-cols-3 ml-16">
          <p className="mt-5 mb-2 col-span-3">{'其他設備'}</p>
          <antd.Form.Item name="sand" className="!mb-0">
            <CustomCheckbox label="砂池" />
          </antd.Form.Item>

          <antd.Form.Item name="paddlingPool" className="!mb-0">
            <CustomCheckbox label="戲水池" />
          </antd.Form.Item>

          <antd.Form.Item name="archeryRange" className="!mb-0">
            <CustomCheckbox label="射箭場" />
          </antd.Form.Item>

          <antd.Form.Item name="playArea" className="!mb-0">
            <CustomCheckbox label="兒童遊戲區" />
          </antd.Form.Item>

          <antd.Form.Item name="badmintonCourt" className="!mb-0">
            <CustomCheckbox label="羽球場" />
          </antd.Form.Item>

          <antd.Form.Item name="volleyballCourt" className="!mb-0">
            <CustomCheckbox label="排球場" />
          </antd.Form.Item>

          <antd.Form.Item name="restArea" className="!mb-0">
            <CustomCheckbox label="休憩區(哈比小屋)" />
          </antd.Form.Item>

          <antd.Form.Item name="picnicArea" className="!mb-0">
            <CustomCheckbox label="野餐區" />
          </antd.Form.Item>

          <antd.Form.Item name="landscapeArea" className="!mb-0">
            <CustomCheckbox label="景觀休息區(散椅)" />
          </antd.Form.Item>
        </div>

        <div className="grid ml-16 mt-5">
          <p className="mb-2 ">{'租用設備'}</p>
          {equipments.map((item) => (
            <antd.Form.Item name={`eq_${item.id}`} className="!mb-0">
              <CustomCheckbox label={`${item.name} $${item.pricing}`} />
            </antd.Form.Item>
          ))}
        </div>

        <antd.Form.Item className="text-center">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};

const CustomCheckbox = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}) => (
  <div className="flex space-x-3">
    <antd.Checkbox
      checked={value}
      onChange={(e) => {
        onChange && onChange(e.target.checked);
      }}
    >
      {label}
    </antd.Checkbox>
  </div>
);
