/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';

import { AppContext } from '../AppContext';

import { AEMealCategories } from '../modals/AEMealCategories';
import { DangerButton } from '../components/DangerButton';

const MealCategoriesPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const init = async (page = currentPage, name?: string) => {
    setSpinning(true);
    const qstring = {
      sort: 'createdAt:desc',
      pagination: { page, pageSize },
      filters: name ? { name: { $contains: name } } : undefined,
    };
    const data = await appCtx.fetch(
      'get',
      '/api/meal-categories?' + qs.stringify(qstring),
    );
    const temp = data.data.map((item: any) => {
      return {
        id: item.id,
        ...item.attributes,
      };
    });

    console.log(temp);
    setDataSource(temp);
    setTotal(data.meta.pagination.total);
    setCurrentPage(data.meta.pagination.page);
    setSpinning(false);
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  const [dataSource, setDataSource] = React.useState<MealCategory[]>([]); //coulmns data

  const delMealCate = async (id: number) => {
    await appCtx.fetch('delete', `/api/meal-categories/${id}`);
    init();
  };

  interface MealCategory {
    name: string;
  }

  const columns: ColumnsType<MealCategory> = [
    {
      title: '名稱',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '操作',
      align: 'center',
      render: (item) => (
        <>
          <antd.Button
            className="mr-3"
            type="primary"
            onClick={() =>
              appCtx.setModal(<AEMealCategories item={item} onSuccess={init} />)
            }
          >
            編輯
          </antd.Button>
          <DangerButton
            title="刪除"
            message="確定要刪除餐點類別?"
            onClick={() => delMealCate(item.id)}
          />
        </>
      ),
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Input.Search
          placeholder="搜尋名稱"
          onSearch={(value) => init(currentPage, value)}
          style={{ width: 200 }}
        />

        <div className="flex-1" />
        <antd.Button
          type="primary"
          onClick={() => appCtx.setModal(<AEMealCategories onSuccess={init} />)}
        >
          新增
        </antd.Button>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
      />
    </antd.Spin>
  );
};

export { MealCategoriesPage };
