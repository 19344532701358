import React from 'react';
import * as antd from 'antd';
import moment from 'moment';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const AEVipMember = ({
  item,
  onSuccess,
}: {
  item?: any;
  onSuccess: () => void;
}) => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);

  const onFinish = async (values: any) => {
    setSpinning(true);

    let data: any = null;

    if (item?.id) {
      data = await appCtx.fetch('put', '/api/vip-members/' + item.id, {
        data: {
          userName: values.userName,
          phone: values.phone,
          dateOfBirth: values.dateOfBirth,
          email: values.email,
          blocked: values.blocked,
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/vip-members', {
        data: {
          cardNumber: values.cardNumber,
          userName: values.userName,
          phone: values.phone,
          dateOfBirth: values.dateOfBirth,
          email: values.email,
          appliedAt: values.appliedAt,
        },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add('success', (item?.id ? '編輯' : '新增') + 'VIP會員成功');
      onSuccess();
    }
    setSpinning(false);
  };

  return (
    <antd.Spin spinning={spinning}>
      <antd.Form
        initialValues={{
          cardNumber: item?.cardNumber,
          userName: item?.userName,
          phone: item?.phone,
          dateOfBirth: item ? moment(item.dateOfBirth) : undefined,
          email: item?.email,
          appliedAt: item ? moment(item.appliedAt) : undefined,
        }}
        onFinish={onFinish}
      >
        <h5 className="font-weight-bold mb-4">{item ? '編輯' : '新增'}</h5>

        <antd.Form.Item
          name="cardNumber"
          label="卡號"
          rules={[{ required: true, message: '請輸入卡號' }]}
        >
          <antd.Input placeholder="請輸入卡號" disabled={!!item} />
        </antd.Form.Item>

        <antd.Form.Item
          name="userName"
          label="名稱"
          rules={[{ required: true, message: '請輸入名稱' }]}
        >
          <antd.Input placeholder="請輸入名稱" />
        </antd.Form.Item>

        <antd.Form.Item
          name="phone"
          label="手機"
          rules={[{ required: true, message: '請輸入手機' }]}
        >
          <antd.Input placeholder="請輸入手機" />
        </antd.Form.Item>

        <antd.Form.Item
          name="dateOfBirth"
          label="生日"
          rules={[{ required: true, message: '請輸入生日' }]}
        >
          <antd.DatePicker placeholder={'請輸入生日'} />
        </antd.Form.Item>

        <antd.Form.Item
          name="email"
          label="電子信箱"
          rules={[{ required: true, message: '請輸電子信箱' }]}
        >
          <antd.Input placeholder="請輸入電子信箱" />
        </antd.Form.Item>

        {item && (
          <antd.Form.Item name="blocked" label="封鎖卡片">
            <antd.Switch defaultChecked={item.blocked} />
          </antd.Form.Item>
        )}

        <antd.Form.Item
          name="appliedAt"
          label="辦卡日"
          rules={[{ required: true, message: '請輸入辦卡日' }]}
        >
          <antd.DatePicker
            disabled={!!item}
            placeholder={'請輸入辦卡日'}
            // showTime
          />
        </antd.Form.Item>

        <antd.Form.Item className="text-center">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};
