/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';

import { AppContext } from '../AppContext';
import { AddAccount } from '../modals/AddAccount';
import dayjs from 'dayjs';

const AccountsPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);

  const init = async (username?: string) => {
    setSpinning(true);
    const qstring = {
      filters: username ? { username: { $contains: username } } : undefined,
    };
    const data = await appCtx.fetch(
      'get',
      '/api/users?' + qs.stringify(qstring),
    );
    setDataSource(
      data.sort(
        (a: any, b: any) =>
          dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
      ),
    );
    console.log(data);
    setSpinning(false);
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  interface user {
    department: string;
    employeeNo: string;
    username: string;
    phone: string;
    email: string;
    createdAt: string;
  }

  const [dataSource, setDataSource] = React.useState<user[]>([]); //coulmns data

  const convertDep = (input: string) => {
    switch (input) {
      case 'Operation':
        return '營運主管';
      case 'Reservation':
        return '訂房部門';
      case 'Repast':
        return '餐飲部門';
      case 'Accounting':
        return '會計部門';
      default:
        return '資料錯誤';
    }
  };

  const columns: ColumnsType<user> = [
    {
      title: '部門',
      align: 'center',
      render: (item) => {
        return <>{convertDep(item.rolename)}</>;
      },
    },
    {
      title: '員工編號',
      align: 'center',
      dataIndex: 'employeeNo',
    },
    {
      title: '人員名稱',
      align: 'center',
      dataIndex: 'username',
    },
    {
      title: '手機',
      align: 'center',
      dataIndex: 'phone',
    },
    {
      title: '電子信箱',
      align: 'center',
      dataIndex: 'email',
    },
    {
      title: '創建日期',
      align: 'center',
      render: (item) => {
        return <>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</>;
      },
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex  mb-2">
        <antd.Input.Search
          placeholder="搜尋人員名稱"
          onSearch={(value) => init(value)}
          style={{ width: 200 }}
        />
        <div className="flex-1" />
        <antd.Button
          type="primary"
          onClick={() => appCtx.setModal(<AddAccount onSuccess={init} />)}
        >
          新增
        </antd.Button>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          pageSize: 10,
          total: dataSource.length,
        }}
      />
    </antd.Spin>
  );
};

export { AccountsPage };
