import React from 'react';
import * as antd from 'antd';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const AddAccount = ({ onSuccess }: { onSuccess: () => void }) => {
  const appCtx = React.useContext(AppContext);

  const onFinish = async (values: any) => {
    console.log(values);

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email)) {
      Notification.add('error', 'Email 格式輸入錯誤');
      return;
    }

    if (!/^[0-9]*$/.test(values.phone)) {
      Notification.add('error', '手機格式輸入錯誤');
      return;
    }

    const data = await appCtx.fetch('post', '/api/account', {
      username: values.username,
      email: values.email,
      password: values.password,
      phone: values.phone,
      employeeNo: values.employeeNo,
      role: values.role,
    });

    if (data) {
      appCtx.setModal(null);
      Notification.add('success', '新增帳號成功');
      onSuccess();
    }
  };

  return (
    <antd.Form
      onFinish={onFinish}
      initialValues={{ type: 'proxy', routes: '*', target: 'localhost:8080' }}
    >
      <h5 className="font-weight-bold mb-4">新增</h5>

      <antd.Form.Item
        name="role"
        label="部門"
        rules={[{ required: true, message: '請輸入部門' }]}
      >
        <antd.Select placeholder="請輸入部門">
          <antd.Select.Option value="Operation">營運主管</antd.Select.Option>
          <antd.Select.Option value="Reservation">訂房部門</antd.Select.Option>
          <antd.Select.Option value="Repast">餐飲部門</antd.Select.Option>
          <antd.Select.Option value="Accounting">會計部門</antd.Select.Option>
        </antd.Select>
      </antd.Form.Item>

      <antd.Form.Item
        name="employeeNo"
        label="員工編號"
        rules={[{ required: true, message: '請輸入員工編號' }]}
      >
        <antd.Input placeholder="請輸入員工編號" />
      </antd.Form.Item>

      <antd.Form.Item
        name="username"
        label="人員名稱"
        rules={[{ required: true, message: '請輸入人員名稱' }]}
      >
        <antd.Input placeholder="請輸入人員名稱" />
      </antd.Form.Item>

      <antd.Form.Item
        name="phone"
        label="手機"
        rules={[{ required: true, message: '請輸入手機' }]}
      >
        <antd.Input placeholder="請輸入手機" />
      </antd.Form.Item>

      <antd.Form.Item
        name="email"
        label="電子信箱"
        rules={[{ required: true, message: '請輸入電子信箱' }]}
      >
        <antd.Input placeholder="請輸入電子信箱" />
      </antd.Form.Item>

      <antd.Form.Item
        name="password"
        label="密碼"
        rules={[{ required: true, message: '請輸入密碼' }]}
      >
        <antd.Input.Password placeholder="請輸入密碼" />
      </antd.Form.Item>

      <antd.Form.Item className="text-center">
        <antd.Button type="primary" htmlType="submit">
          確定
        </antd.Button>
      </antd.Form.Item>
    </antd.Form>
  );
};
