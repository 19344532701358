/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';
import dayjs from 'dayjs';

import { AppContext } from '../AppContext';

import { AddEvent } from '../modals/AEEvent';
import { DangerButton } from '../components/DangerButton';

const EventsPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const init = async (page = currentPage, title?: string) => {
    setSpinning(true);
    const qstring = {
      sort: 'createdAt:desc',
      populate: ['images'],
      pagination: { page, pageSize },
      filters: title ? { title: { $contains: title } } : undefined,
    };
    const data = await appCtx.fetch(
      'get',
      '/api/events?' + qs.stringify(qstring),
    );
    const temp = data.data.map((item: any) => {
      return {
        id: item.id,
        ...item.attributes,
      };
    });

    console.log(temp);
    setDataSource(temp);
    setTotal(data.meta.pagination.total);
    setCurrentPage(data.meta.pagination.page);
    setSpinning(false);
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  const deleteEvent = async (id: number) => {
    await appCtx.fetch('delete', `/api/events/${id}`);
    init();
  };

  interface Event {
    key: string;
    name: string;
    address: string;
  }

  const [dataSource, setDataSource] = React.useState<Event[]>([]); //coulmns data

  const columns: ColumnsType<Event> = [
    {
      title: '標題',
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '內容',
      align: 'left',
      width: '30%',
      render: (item) => (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      ),
    },
    {
      title: '圖片',
      align: 'center',
      render: (item) => (
        <div className="flex justify-center items-center space-x-3">
          <antd.Image.PreviewGroup>
            {item.images?.data &&
              item.images.data.map((image: any) => {
                return <antd.Image width={80} src={image.attributes.url} />;
              })}
          </antd.Image.PreviewGroup>
        </div>
      ),
    },
    {
      title: '活動日期',
      align: 'center',
      dataIndex: 'eventDate',
    },
    {
      title: '是否隱藏',
      align: 'center',
      render: (item) => <antd.Switch checked={item.hidden} />,
    },
    {
      title: '創建日期',
      align: 'center',
      render: (item) => {
        return <>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</>;
      },
    },
    {
      title: '操作',
      align: 'center',
      width: 180,
      render: (item) => (
        <>
          <antd.Button
            className="mr-3"
            type="primary"
            onClick={() =>
              appCtx.setModal(<AddEvent item={item} onSuccess={init} />, 1250)
            }
          >
            編輯
          </antd.Button>
          <DangerButton
            title="刪除"
            message="確定要刪除活動紀錄?"
            onClick={() => deleteEvent(item.id)}
          />
        </>
      ),
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Input.Search
          placeholder="搜尋標題"
          onSearch={(value) => init(currentPage, value)}
          style={{ width: 200 }}
        />

        <div className="flex-1" />
        <antd.Button
          type="primary"
          onClick={() => appCtx.setModal(<AddEvent onSuccess={init} />, 1250)}
        >
          新增
        </antd.Button>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
      />
    </antd.Spin>
  );
};

export { EventsPage };
