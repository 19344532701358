import { Form, Spin, Button, Table, Input, DatePicker } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import qs from 'qs';
import { useState, useContext, useEffect, useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { AccountingDocumentDetail, Grade } from './types';
import { AppContext } from '../../AppContext';

import './GradeLogPage.css';
import moment from 'moment';

const MemoizedTable = memo((props: any) => {
  console.log('rendering');
  return <Table {...props} />;
});

interface SubTableProps {
  gradeId: number;
  visible: boolean;
}

const SubTable = ({ gradeId, visible }: SubTableProps) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);
  const [dataSource, setDataSource] = useState<AccountingDocumentDetail[]>([]);
  const [spinning, setSpinning] = useState(false);
  const [date, setDate] = useState<moment.Moment | null>(moment());

  const init = useCallback(async () => {
    setSpinning(true);
    const info = {
      // sort: ['code:asc'],
      pagination: { page: 1, pageSize: 250 },
      populate: ['grade', 'sub_grade', 'subpoena', 'vendor'],
      filters: {
        $or: [
          { sub_grade: { grade: { id: { $eq: gradeId } } } },
          { grade: { id: { $eq: gradeId } } },
        ],
        subpoena: {
          date: {
            $eq: date?.format('YYYY-MM-DD'),
          },
        },
      },
    };

    const result = await appCtx.fetch(
      'get',
      '/api/subpoena-details?' + qs.stringify(info),
    );

    if (result) {
      let data = result.data;

      const dataWithKeys = data.map(
        (item: { id: number; attributes: any }) => ({
          key: item.id,
          parentId: item.attributes.subpoena.data.id,
          parentDate: item.attributes.subpoena.data.attributes.date,
          name: item.attributes.grade.data
            ? item.attributes.grade.data.attributes.name
            : item.attributes.sub_grade.data.attributes.name,
          code: item.attributes.grade.data
            ? item.attributes.grade.data.attributes.code
            : item.attributes.sub_grade.data.attributes.code,
          vendor: item.attributes.vendor.data
            ? item.attributes.vendor.data.name
            : '',
          summary: item.attributes.summary,
          lender: item.attributes.lender,
          debtor: item.attributes.debit,
        }),
      );
      setDataSource(dataWithKeys);
      setSpinning(false);
    }
  }, [appCtx, gradeId, date]);

  useEffect(() => {
    visible && init();
    !visible && setDataSource([]);
  }, [visible, init]);

  const subTableColumns: ColumnsType<AccountingDocumentDetail> = [
    {
      title: '日期',
      align: 'left',
      dataIndex: 'parentDate',
      key: 'parentDate',
    },
    {
      title: '科目編號',
      align: 'left',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '科目名稱',
      align: 'left',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '供應商',
      align: 'left',
      dataIndex: 'vendor',
      key: 'vendor',
    },
    {
      title: '摘要',
      align: 'left',
      dataIndex: 'summary',
      key: 'summary',
    },
    {
      title: '借方',
      align: 'left',
      dataIndex: 'lender',
      key: 'lender',
    },
    {
      title: '貸方',
      align: 'left',
      dataIndex: 'debtor',
      key: 'debtor',
    },
  ];

  return (
    <Spin spinning={spinning}>
      <DatePicker
        className="my-2"
        onChange={(val) => setDate(val)}
        value={date}
      />
      {/* <div className="border-gray-500 border-y-2" /> */}
      <Table
        columns={subTableColumns}
        dataSource={dataSource}
        pagination={false}
        rowClassName={`subgrades-table-row`}
        onRow={(record: AccountingDocumentDetail) => ({
          onClick: () =>
            history.push(`/accounting-documents/${record.parentId}`),
          className: 'cursor-pointer',
        })}
      />
    </Spin>
  );
};

const columns = [
  {
    title: '科目編號',
    align: 'left',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '科目名稱',
    align: 'left',
    dataIndex: 'name',
    key: 'name',
  },
  Table.EXPAND_COLUMN,
];

const expObj = {
  expandRowByClick: true,
  expandedRowClassName: () => 'grade-log-subTable-container',
  expandedRowRender: (record: Grade, _: any, __: any, expanded: boolean) => {
    return <SubTable gradeId={record.key} visible={expanded} />;
  },
  expandIcon: ({
    expanded,
    onExpand,
    record,
  }: {
    expanded: boolean;
    onExpand: (record: Grade, e: React.MouseEvent<HTMLElement>) => void;
    record: Grade;
  }) => {
    return expanded ? (
      <DownOutlined onClick={(e) => onExpand(record, e)} />
    ) : (
      <RightOutlined onClick={(e) => onExpand(record, e)} />
    );
  },
};

const GradeLogPage = () => {
  const appCtx = useContext(AppContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [dataSource, setDataSource] = useState<Grade[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<Grade[]>([]);
  const [spinning, setSpinning] = useState(false);
  const pageSize = 250;
  const baseURL = '/api/grades';

  const init = useCallback(
    async (mountedContainer = { isMounted: true }) => {
      setSpinning(true);
      const pagination = {
        sort: ['code:asc'],
        pagination: { page: 1, pageSize },
      };

      const result = await appCtx.fetch(
        'get',
        baseURL + '?' + qs.stringify(pagination),
      );

      if (result) {
        const pageCount = result.meta.pagination.pageCount;
        let data = result.data;

        for (let i = 2; i <= pageCount; i++) {
          const result = await appCtx.fetch(
            'get',
            baseURL + '?' + qs.stringify({ pagination: { page: i, pageSize } }),
          );
          data = data.concat(result.data);
        }

        const dataWithKeys = data.map(
          (item: { id: number; attributes: Grade }) => ({
            key: item.id,
            name: item.attributes.name,
            code: item.attributes.code,
          }),
        );
        if (mountedContainer.isMounted) {
          setDataSource(dataWithKeys);
          setFilteredDataSource(dataWithKeys);
          setSpinning(false);
        }
      }
    },
    [appCtx],
  );

  useEffect(() => {
    appCtx.redirect();
  }, [appCtx]);

  useEffect(() => {
    const mountedContainer = { isMounted: true };
    init(mountedContainer);
    return () => {
      mountedContainer.isMounted = false;
    };
  }, [init]);

  const filterDataSource = (values: { [key: string]: string }) =>
    dataSource.filter((row) => {
      return row.code.includes(values.gradeCode);
    });

  return (
    <Spin spinning={spinning}>
      <Form
        layout="inline"
        onFinish={(values) => setFilteredDataSource(filterDataSource(values))}
        onValuesChange={(values) => {
          !values.gradeCode && setFilteredDataSource(dataSource);
        }}
        className="mb-3"
      >
        <Form.Item label="科目查詢" name="gradeCode">
          <Input
            placeholder="輸入科目編號"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="px-large"
            disabled={!searchTerm}
            htmlType="submit"
          >
            查詢
          </Button>
        </Form.Item>
      </Form>

      <MemoizedTable
        columns={columns}
        dataSource={filteredDataSource}
        pagination={false}
        expandable={expObj}
      />
    </Spin>
  );
};

export { GradeLogPage };
