import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './AppContext';
import { ConfigProvider } from 'antd';

import './index.css';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-calendar-timeline/lib/Timeline.css';

import moment from 'moment';
import 'moment/locale/zh-tw';

import zh_TW from 'antd/lib/locale/zh_TW';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';

moment.locale('zh-tw');
dayjs.locale('zh-tw');

ReactDOM.render(
  <ConfigProvider locale={zh_TW}>
    <AppProvider>
      <App />
    </AppProvider>
  </ConfigProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
