import { Form, Spin, DatePicker, Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import qs from 'qs';
import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { AccountingDocument } from './types';
import { AppContext } from '../../AppContext';

const TimeLogPage = () => {
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const [date, setDate] = useState<moment.Moment | null>(null);
  const [dataSource, setDataSource] = useState<AccountingDocument[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [spinning, setSpinning] = useState(false);

  const pageSize = 10;

  const init = useCallback(
    async (page = currentPage, selectedDate = date) => {
      setSpinning(true);
      const pagination = {
        sort: 'createdAt:desc',
        pagination: { page, pageSize },
        filters: selectedDate
          ? {
              date: {
                $eq: selectedDate.format('YYYY-MM-DD'),
              },
            }
          : undefined,
        populate: 'subpoena_details',
      };
      const result = await appCtx.fetch(
        'get',
        '/api/subpoenas?' + qs.stringify(pagination),
      );
      if (result) {
        const dataWithKeys = result.data.map((ad: any) => ({
          key: ad.id,
          code: ad.attributes.Code,
          date: ad.attributes.date,
          debtor: ad.attributes.subpoena_details.data.reduce(
            (acc: number, obj: any) => +obj.attributes.debit + acc,
            0,
          ),
          lender: ad.attributes.subpoena_details.data.reduce(
            (acc: number, obj: any) => +obj.attributes.lender + acc,
            0,
          ),
        }));
        setDataSource(dataWithKeys);
        setTotal(result.meta.pagination.total);
        setSpinning(false);
      }
    },
    [appCtx, currentPage, date],
  );

  useEffect(() => {
    appCtx.redirect();
  }, [appCtx]);

  const columns: ColumnsType<AccountingDocument> = [
    {
      title: '日期',
      align: 'left',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '傳票編號',
      align: 'left',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '借方',
      align: 'center',
      dataIndex: 'debtor',
      key: 'debtor',
    },
    {
      title: '貸方',
      align: 'center',
      dataIndex: 'lender',
      key: 'lender',
    },
  ];

  return (
    <Spin spinning={spinning}>
      <Form layout="inline" onFinish={() => init(1)} className="mb-3">
        <Form.Item label="日期查詢">
          <DatePicker onChange={(val) => setDate(val)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="px-large"
            disabled={!date}
            htmlType="submit"
          >
            查詢
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={dataSource}
        onRow={(record: AccountingDocument) => ({
          onClick: () => history.push(`accounting-documents/${record.key}`),
          className: 'cursor-pointer',
        })}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => {
            init(page);
            setCurrentPage(page);
          },
        }}
      />
    </Spin>
  );
};

export { TimeLogPage };
