/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as antd from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const AddNews = ({
  item,
  onSuccess,
}: {
  item?: any;
  onSuccess: () => void;
}) => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const handleChange = ({ fileList }: any) => setFileList(fileList.slice(0, 5));

  React.useEffect(() => {
    item?.images?.data &&
      setFileList(
        item.images.data.map((item: any) => {
          console.log(item.attributes.url);
          return { id: item.id, url: item.attributes.url };
        }),
      );
  }, []);

  const onFinish = async (values: any) => {
    if (fileList.length === 0) {
      Notification.add('error', '請至少選擇一張圖片');
      return;
    }

    setSpinning(true);

    const ids = await appCtx.upload(fileList.filter((item) => !item.id));
    if (!ids) {
      Notification.add('error', '上傳圖片失敗');
      return;
    }

    let data: any = null;

    if (item?.id) {
      data = await appCtx.fetch('put', '/api/news/' + item.id, {
        data: {
          title: values.title,
          startDate: values.time[0],
          endDate: values.time[1],
          content: values.content,
          isTop: values.top,
          images: fileList
            .filter((item) => item.id)
            .map((item) => item.id)
            .concat(ids),
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/news', {
        data: {
          title: values.title,
          startDate: values.time[0],
          endDate: values.time[1],
          content: values.content,
          isTop: values.top,
          images: ids,
        },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.id ? '編輯' : '新增') + '最新消息成功',
      );
      onSuccess();
    }
    setSpinning(false);
  };

  return (
    <antd.Spin spinning={spinning} tip="上傳中...">
      <antd.Form
        initialValues={{
          title: item?.title,
          content: item?.content,
          time: item
            ? [moment(item.startDate), moment(item.endDate)]
            : undefined,
        }}
        onFinish={onFinish}
      >
        <h5 className="font-weight-bold mb-4">{item ? '編輯' : '新增'}</h5>

        <antd.Form.Item
          name="title"
          label="標題"
          rules={[{ required: true, message: '請輸入標題' }]}
        >
          <antd.Input placeholder="請輸入標題" />
        </antd.Form.Item>

        <antd.Form.Item
          name="content"
          label="內容"
          rules={[{ required: true, message: '請輸入內容' }]}
        >
          <antd.Input.TextArea rows={10} placeholder="請輸入內容" />
        </antd.Form.Item>

        <antd.Upload
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          multiple={true}
          showUploadList={{ showPreviewIcon: false }}
          accept={'.bmp,.jpg,.jpeg,.png,.gif'}
        >
          {fileList.length >= 5 ? null : (
            <>
              <PlusOutlined />
              <div>{`還可上傳${5 - fileList.length}張`}</div>
            </>
          )}
        </antd.Upload>

        <antd.Form.Item
          name="time"
          label="時間"
          rules={[{ required: true, message: '請輸入時間' }]}
        >
          <antd.DatePicker.RangePicker />
        </antd.Form.Item>

        <antd.Form.Item name="top" label="是否置頂">
          <antd.Switch defaultChecked={item?.isTop} />
        </antd.Form.Item>

        <antd.Form.Item className="text-center">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};
