/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';

import { AppContext } from '../AppContext';
import { AERoomCollection } from '../modals/AERoomCollection';
import { EditRoomPricing } from '../modals/EditRoomPricing';
import { RoomPriceProvider } from '../modals/utils/RoomPriceContext';
import { DangerButton } from '../components/DangerButton';

const RoomCollectionPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<RoomCollection[]>([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;
  const [equipments, setEquipments] = React.useState<any[]>([]);

  const init = async (page = currentPage, title?: string) => {
    setSpinning(true);
    const eq = await appCtx.fetch('get', '/api/rental-equipments');
    if (eq) {
      const temp = eq.data.map((item: any) => {
        return {
          id: item.id,
          name: item.attributes.name,
          pricing: item.attributes.pricing,
        };
      });
      setEquipments(temp);
    }

    const qstring = {
      sort: 'createdAt:desc',
      populate: ['images', 'rental_equipments', 'rooms'],
      pagination: { page, pageSize },
    };
    const data = await appCtx.fetch(
      'get',
      '/api/room-collections?' + qs.stringify(qstring),
    );
    if (data) {
      const temp = data.data.map((item: any) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });

      console.log(temp);
      setDataSource(temp);
      setTotal(data.meta.pagination.total);
      setCurrentPage(data.meta.pagination.page);
    }

    setSpinning(false);
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  interface RoomCollection {
    name: string;
    count: number;
    maxCount: number;
    checkinTime: string;
    checkoutTime: string;
    intro: string;
    shampoo: boolean;
    bathTowel: boolean;
    bodyWash: boolean;
    babyBath: boolean;
    towel: boolean;
    toothpaste: boolean;
    toothbrush: boolean;
    hairDryer: boolean;
    airConditioner: boolean;
    thermos: boolean;
    refrigerator: boolean;
    socket: boolean;
    slippers: boolean;
    extraQuilt: boolean;
    extraPillow: boolean;
    pillowAndQuilt: boolean;
    parkingFree: boolean;
    electricCarParkingFree: boolean;
    electricCarParkingPaid: boolean;
    wirelessNetwork: boolean;
    breakfastFree: boolean;
    breakfastPaid: boolean;
    lunchFree: boolean;
    lunchPaid: boolean;
    afternoonTeaFree: boolean;
    afternoonTeaPaid: boolean;
    dinner: boolean;
    dinnerPaid: boolean;
    canopy: boolean;
    barbecueEquipment: boolean;
    sand: boolean;
    paddlingPool: boolean;
    archeryRange: boolean;
    playArea: boolean;
    badmintonCourt: boolean;
    volleyballCourt: boolean;
    restArea: boolean;
    picnicArea: boolean;
    landscapeArea: boolean;
    createdAt: string;
  }

  const deleteRoomCollection = async (id: number) => {
    await appCtx.fetch('delete', `/api/room-collections/${id}`);
    init();
  };

  const columns: ColumnsType<RoomCollection> = [
    { title: '房型名稱', align: 'center', dataIndex: 'name' },
    {
      title: '圖片',
      align: 'center',
      render: (item) => (
        <div className="flex justify-center items-center space-x-3">
          <antd.Image.PreviewGroup>
            {item.images?.data &&
              item.images.data.map((image: any) => {
                return <antd.Image width={80} src={image.attributes.url} />;
              })}
          </antd.Image.PreviewGroup>
        </div>
      ),
    },

    // {
    //   title: '創建日期',
    //   align: 'center',
    //   render: (item) => (
    //     <>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
    //   ),
    // },
    {
      title: '編輯',
      align: 'center',
      width: 300,
      render: (item) => (
        <>
          <antd.Button
            className="mr-3"
            type="primary"
            onClick={() =>
              appCtx.setModal(
                <RoomPriceProvider>
                  <EditRoomPricing item={item} />
                </RoomPriceProvider>,
                1200,
              )
            }
          >
            編輯房價
          </antd.Button>
          <antd.Button
            className="mr-3"
            type="primary"
            onClick={() =>
              appCtx.setModal(
                <AERoomCollection
                  item={item}
                  onSuccess={init}
                  equipments={equipments}
                />,
                750,
              )
            }
          >
            編輯房型
          </antd.Button>
        </>
      ),
    },
    {
      title: '',
      align: 'center',
      width: 180,
      render: (item) => (
        <>
          <DangerButton
            title="刪除"
            message="確定要刪除?"
            onClick={() => deleteRoomCollection(item.id)}
          />
        </>
      ),
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Button
          type="primary"
          onClick={() =>
            appCtx.setModal(
              <AERoomCollection onSuccess={init} equipments={equipments} />,
              750,
            )
          }
        >
          新增房型
        </antd.Button>

        <div className="flex-1" />
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
      />
    </antd.Spin>
  );
};

export { RoomCollectionPage };
