import React, { useState } from 'react';
import { Modal, Button, Form, Input } from 'antd';

import { AppContext } from '../AppContext';
import { AccountingDocumentDetail } from '../pages/Accounting/types';
import { addAccountingDocument } from '../pages/Accounting/utils';

interface AddADToCommonProps {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  data: AccountingDocumentDetail[];
}

const AddADToCommon = ({
  visible,
  handleOk,
  handleCancel,
  data,
}: AddADToCommonProps) => {
  const [form] = Form.useForm();
  const appCtx = React.useContext(AppContext);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onOk = () => {
    return form
      .validateFields()
      .then(async (values) => {
        setConfirmLoading(true);
        const result = await addAccountingDocument(
          '/api/common-subpoenas',
          'post',
          appCtx,
          data,
          { name: values.name },
          'addADToCommon',
        );
        setConfirmLoading(false);
        form.resetFields();
        handleOk();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onCancel = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal
      visible={visible}
      title={<div className="grid place-items-center">新增至常用傳票庫</div>}
      closable={false}
      onCancel={onCancel}
      footer={[
        <div className="flex justify-evenly" key="footer">
          <Button onClick={onCancel} className="px-large">
            取消
          </Button>
          <Button
            type="primary"
            onClick={onOk}
            loading={confirmLoading}
            className="px-large"
          >
            確定
          </Button>
        </div>,
      ]}
      destroyOnClose={true}
    >
      <Form form={form}>
        <Form.Item name="name" label="傳票名稱" rules={[{ required: true }]}>
          <Input placeholder="輸入檔名" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddADToCommon;
