import './App.css';

import * as ReactRouterDOM from 'react-router-dom';

import { MainPage, Menu } from './pages/MainPage';
import { LaunchPage, LoginPage, NotFoundPage } from './pages/LaunchPage';
import { HomePage } from './pages/HomePage';
import { AccountsPage } from './pages/AccountsPage';
import { NewsPage } from './pages/NewsPage';
import { EventsPage } from './pages/EventsPage';
import { PlayGroundPage } from './pages/PlayGroundPage';
import { FoodStoriesPage } from './pages/FoodStoriesPage';
import { MealSetsPage } from './pages/MealSetsPage';
import { SingleMealsPage } from './pages/SingleMealsPage';
import { MealCategoriesPage } from './pages/MealCategoriesPage';
import { AuditLogsPage } from './pages/AuditLogsPage';
import { VipMemberPage } from './pages/VipMemberPage';
import { RoomCollectionPage } from './pages/RoomCollectionPage';
import { RentalEquipmentPage } from './pages/RentalEquipmentPage';
import { RoomStatusesPage } from './pages/RoomStatusesPage';
import { EntryOrderPage } from './pages/EntryOrderPage';
import { OrdersPage } from './pages/OrdersPage';
import { AccountingDocumentsPage } from './pages/Accounting/AccountingDocumentsPage';
import { AccountingDocumentsDetailsPage } from './pages/Accounting/AccountingDocumentDetailsPage';
import { CommonAccountingDocumentsPage } from './pages/Accounting/CommonAccountingDocumentsPage';
import { VendorsPage } from './pages/Accounting/VendorsPage';
import { GradesPage } from './pages/Accounting/GradesPage';
import { TimeLogPage } from './pages/Accounting/TimeLogPage';
import { GradeLogPage } from './pages/Accounting/GradeLogPage';
import { VendorDocumentDetailsPage } from './pages/Accounting/VendorDocumentDetailsPage';
import { OrdersListPage } from './pages/OrdersListPage';
import NewOrdersPage from './pages/NewOrdersPage';
import { useIsMobile } from './hooks/useIsMobile';

const menus: Menu[] = [
  {
    key: '/homepage',
    title: '首頁',
    component: <HomePage />,
  },
  {
    key: 'operator',
    title: '營運主管',
    subMenu: [
      {
        key: '/news',
        title: '最新消息',
        component: <NewsPage />,
      },
      {
        key: '/events',
        title: '活動紀錄',
        component: <EventsPage />,
      },
      {
        key: '/play-grounds',
        title: '親子設施',
        component: <PlayGroundPage />,
      },
      {
        key: '/food-stories',
        title: '美食情境',
        component: <FoodStoriesPage />,
      },
      {
        key: '/rooms',
        title: '房型設定',
        component: <RoomCollectionPage />,
      },
      //   {
      //     key: '/room-statuses',
      //     title: '房間狀態',
      //     component: <RoomStatusesPage />,
      //   },
      {
        key: '/rental-equipments',
        title: '租用設備設定',
        component: <RentalEquipmentPage />,
      },
      {
        key: '/vip-members',
        title: 'VIP會員',
        component: <VipMemberPage />,
      },
      {
        key: '/accounts',
        title: '帳號權限',
        component: <AccountsPage />,
      },
    ],
  },
  {
    key: 'reservation',
    title: '訂房部門',
    subMenu: [
      //   {
      //     key: '/orders',
      //     title: '訂房',
      //     component: <NewOrdersPage />,
      //   },
      //   {
      //     key: '/orders-list',
      //     title: '訂單查詢',
      //     component: <OrdersListPage />,
      //   },
      {
        key: '/entry-orders',
        title: '入園訂單',
        component: <EntryOrderPage />,
      },
    ],
  },
  {
    key: 'repast',
    title: '餐飲部門',
    subMenu: [
      {
        key: '/meal-categories',
        title: '餐點類別設定',
        component: <MealCategoriesPage />,
      },
      {
        key: '/meal-sets',
        title: '餐點組合',
        component: <MealSetsPage />,
      },
      {
        key: '/single-meals',
        title: '單點餐點',
        component: <SingleMealsPage />,
      },
    ],
  },
  {
    key: 'accounting',
    title: '會計',
    subMenu: [
      {
        key: '/accounting-documents',
        title: '傳票',
        component: <AccountingDocumentsPage />,
      },
      {
        key: '/accounting-documents-common',
        title: '常用傳票',
        component: <CommonAccountingDocumentsPage />,
      },
      {
        key: '/vendors',
        title: '供應商資料',
        component: <VendorsPage />,
      },
      {
        key: '/grades',
        title: '科目設定',
        component: <GradesPage />,
      },
      {
        key: '/accounting-documents-time-log',
        title: '日記帳',
        component: <TimeLogPage />,
      },
      {
        key: '/accounting-documents-grade-log',
        title: '分類帳',
        component: <GradeLogPage />,
      },
    ],
  },
  {
    key: '/audit-logs',
    title: '紀錄',
    component: <AuditLogsPage />,
  },
];

function App() {
  const showOnlyReservation = useIsMobile();

  const newMenus = showOnlyReservation
    ? menus.filter(
        (menuItem) =>
          menuItem.key === '/homepage' || menuItem.key === 'reservation',
      )
    : menus;

  return (
    <ReactRouterDOM.HashRouter>
      <ReactRouterDOM.Switch>
        <ReactRouterDOM.Route path="/" exact component={LaunchPage} />
        <ReactRouterDOM.Route path="/login" component={LoginPage} />

        {newMenus.map((item) => {
          if (item.subMenu) {
            return item.subMenu.map((item) => (
              <ReactRouterDOM.Route key={item.key} path={item.key} exact>
                <MainPage
                  menus={newMenus}
                  title={item.title}
                  content={item.component}
                />
              </ReactRouterDOM.Route>
            ));
          } else {
            return (
              <ReactRouterDOM.Route key={item.key} path={item.key}>
                <MainPage
                  menus={newMenus}
                  title={item.title}
                  content={item.component!}
                />
              </ReactRouterDOM.Route>
            );
          }
        })}

        <ReactRouterDOM.Route path="/accounting-documents/:id">
          <MainPage
            menus={newMenus}
            title="傳票內容"
            content={<AccountingDocumentsDetailsPage />}
          />
        </ReactRouterDOM.Route>
        <ReactRouterDOM.Route path="/vendors/:id">
          <MainPage
            menus={newMenus}
            title="供應商-傳票細項"
            content={<VendorDocumentDetailsPage />}
          />
        </ReactRouterDOM.Route>

        <ReactRouterDOM.Route path="*" component={NotFoundPage} />
      </ReactRouterDOM.Switch>
    </ReactRouterDOM.HashRouter>
  );
}

export default App;
