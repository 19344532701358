/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { AppContext } from '../AppContext';

const HomePage = () => {
  const appCtx = React.useContext(AppContext);

  const redirect = async () => {
    await appCtx.redirect();
  };

  React.useEffect(() => {
    redirect();
  }, []);

  return (
    <>
      <img
        src="assets/ycc-home.png"
        alt=""
        className="w-full h-full object-cover"
      />
    </>
  );
};

export { HomePage };
