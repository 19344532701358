/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { DangerButton } from '../../components/DangerButton';
import Input from 'antd/lib/input/Input';

const CommonAccountingDocumentsPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<
    CommonAccountingDocument[]
  >([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const [form] = antd.Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const pageSize = 10;

  const init = async (page = currentPage, name = '') => {
    setSpinning(true);
    const pagination = {
      sort: 'createdAt:desc',
      pagination: { page, pageSize },
      filters: name ? { name: { $contains: name } } : undefined,
    };
    const result = await appCtx.fetch(
      'get',
      '/api/common-subpoenas?' + qs.stringify(pagination),
    );
    if (result) {
      const dataWithKeys = result.data.map((item: any) => ({
        key: item.id,
        name: item.attributes.name,
      }));
      setDataSource(dataWithKeys);
      setTotal(result.meta.pagination.total);
      setSpinning(false);
    }
  };

  interface CommonAccountingDocument {
    name: string;
    key: number;
  }

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  const columns: ColumnsType<CommonAccountingDocument> = [
    {
      title: '檔名',
      align: 'left',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '操作',
      align: 'center',
      width: 180,
      render: (item) => {
        return (
          <>
            <antd.Button
              className="mr-3"
              type="primary"
              onClick={() =>
                history.push(`accounting-documents/common-${item.key}`)
              }
            >
              修改
            </antd.Button>
            <DangerButton
              title="移除"
              message="確定要刪除傳票?"
              onClick={async () => {
                const result = await appCtx.fetch(
                  'delete',
                  `/api/common-subpoenas/${item.key}`,
                );
                init(currentPage);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div>
        <antd.Form
          form={form}
          requiredMark={false}
          className="mb-8"
          onFinish={(values) => {
            init(1, values.name);
            form.resetFields();
          }}
        >
          <div className="flex gap-3 my-3">
            <antd.Form.Item label="搜尋" name="name" className="mb-0">
              <Input />
            </antd.Form.Item>
            <antd.Button className="px-large" htmlType="submit">
              搜尋
            </antd.Button>
          </div>
        </antd.Form>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => {
            init(page);
            setCurrentPage(page);
          },
        }}
      />
    </antd.Spin>
  );
};

export { CommonAccountingDocumentsPage };
