/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as antd from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';
import { SingleMeal } from '../pages/SingleMealsPage';

export const AESingleMeals = ({
  item,
  onSuccess,
}: {
  item?: SingleMeal;
  onSuccess: () => void;
}) => {
  console.log(item);
  const appCtx = React.useContext(AppContext);

  interface Category {
    id: number;
    name: string;
  }

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [spinTip, setSpinTip] = React.useState<string>('');
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const handleChange = ({ fileList }: any) => setFileList(fileList.slice(0, 5));

  const getCategory = async () => {
    setSpinning(true);
    const data = await appCtx.fetch('get', '/api/meal-categories');

    const temp = data.data.map((item: any): Category => {
      return { id: item.id, name: item.attributes.name };
    });
    setCategories(temp);
    setSpinning(false);
  };
  React.useEffect(() => {
    item?.images &&
      setFileList(
        item.images.map((obj) => {
          return { id: obj.id, url: obj.url };
        }),
      );

    getCategory();
  }, []);

  const onFinish = async (values: any) => {
    if (fileList.length === 0) {
      Notification.add('error', '請至少選擇一張圖片');
      return;
    }

    setSpinning(true);
    setSpinTip('上傳中...');

    const ids = await appCtx.upload(fileList.filter((item) => !item.id));
    if (!ids) {
      Notification.add('error', '上傳圖片失敗');
      return;
    }

    let data: any = null;

    if (item?.key) {
      data = await appCtx.fetch('put', '/api/single-meals/' + item.key, {
        data: {
          name: values.name,
          unit: values.unit,
          pricing: values.pricing,
          meal_category: values.categories,
          images: fileList
            .filter((item) => item.id)
            .map((item) => item.id)
            .concat(ids),
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/single-meals', {
        data: {
          name: values.name,
          unit: values.unit,
          pricing: values.pricing,
          meal_category: values.categories,
          images: ids,
        },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.key ? '編輯' : '新增') + '單點餐點成功',
      );
      onSuccess();
    }
  };

  return (
    <antd.Spin spinning={spinning} tip={spinTip}>
      <antd.Form
        initialValues={{
          categories: item?.categoryId,
          name: item?.name,
          unit: item?.unit,
          pricing: item?.price,
        }}
        onFinish={onFinish}
      >
        <h5 className="mb-4 font-weight-bold">{item ? '編輯' : '新增'} </h5>

        <antd.Form.Item
          name="categories"
          label="類別"
          rules={[{ required: true, message: '請輸入類別' }]}
        >
          {categories.length > 0 && (
            <antd.Select placeholder="請輸入類別">
              {categories.map((item) => (
                <antd.Select.Option value={item.id} key={item.id}>
                  {item.name}
                </antd.Select.Option>
              ))}
            </antd.Select>
          )}
        </antd.Form.Item>

        <antd.Form.Item
          name="name"
          label="名稱"
          rules={[{ required: true, message: '請輸入名稱' }]}
        >
          <antd.Input placeholder="請輸入名稱" />
        </antd.Form.Item>

        <antd.Form.Item
          name="unit"
          label="單位"
          rules={[{ required: true, message: '請輸入單位' }]}
        >
          <antd.Input placeholder="請輸入單位" />
        </antd.Form.Item>

        <antd.Form.Item
          name="pricing"
          label="價格"
          rules={[{ required: true, message: '請輸入價格' }]}
        >
          <antd.InputNumber placeholder="請輸入價格" />
        </antd.Form.Item>

        <antd.Upload
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          multiple={true}
          showUploadList={{ showPreviewIcon: false }}
          accept={'.bmp,.jpg,.jpeg,.png,.gif'}
        >
          {fileList.length >= 5 ? null : (
            <>
              <PlusOutlined />
              <div>{`還可上傳${5 - fileList.length}張`}</div>
            </>
          )}
        </antd.Upload>

        <antd.Form.Item className="text-center">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};
