/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as antd from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

const toBase64 = (file: any) =>
  new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const uploadImageCallBack = (file: any) => {
  return new Promise<any>((resolve, reject) => {
    toBase64(file)
      .then((data) => resolve({ data: { link: data } }))
      .catch((error) => reject(error));
  });
};

export const AddEvent = ({
  item,
  onSuccess,
}: {
  item?: any;
  onSuccess: () => void;
}) => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<any>();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const handleChange = ({ fileList }: any) => setFileList(fileList.slice(0, 5));

  React.useEffect(() => {
    item?.images?.data &&
      setFileList(
        item.images.data.map((item: any) => {
          console.log(item.attributes.url);
          return { id: item.id, url: item.attributes.url };
        }),
      );

    if (item?.content) {
      const contentBlock = htmlToDraft(item.content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setContent(editorState);
      }
    }
  }, []);

  const onFinish = async (values: any) => {
    console.log(convertToRaw(content.getCurrentContent()));
    // return;
    if (!content) {
      Notification.add('error', '請輸入內容');
      return;
    }

    if (fileList.length === 0) {
      Notification.add('error', '請至少選擇一張圖片');
      return;
    }

    setSpinning(true);

    const ids = await appCtx.upload(fileList.filter((item) => !item.id));
    if (!ids) {
      Notification.add('error', '上傳圖片失敗');
      return;
    }

    let data: any = null;

    if (item?.id) {
      data = await appCtx.fetch('put', '/api/events/' + item.id, {
        data: {
          title: values.title,
          content: draftToHtml(convertToRaw(content.getCurrentContent())),
          eventDate: values.eventDate,
          hidden: values.hidden,
          images: fileList
            .filter((item) => item.id)
            .map((item) => item.id)
            .concat(ids),
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/events', {
        data: {
          title: values.title,
          content: draftToHtml(convertToRaw(content.getCurrentContent())),
          eventDate: values.eventDate,
          hidden: values.hidden,
          images: ids,
        },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.id ? '編輯' : '新增') + '活動紀錄成功',
      );
      onSuccess();
    }
  };

  return (
    <antd.Spin spinning={spinning} tip="上傳中...">
      <antd.Form
        initialValues={{
          title: item?.title,
          content: item?.content,
          eventDate: item ? moment(item.eventDate) : undefined,
          hidden: item?.hidden,
        }}
        onFinish={onFinish}
      >
        <h5 className="font-weight-bold mb-4">{item?.id ? '編輯' : '新增'}</h5>

        <antd.Form.Item
          name="title"
          label="標題"
          rules={[{ required: true, message: '請輸入標題' }]}
        >
          <antd.Input placeholder="請輸入標題" />
        </antd.Form.Item>

        <antd.Form.Item
          name="content"
          label="內容"
          rules={[{ message: '請輸入內容' }]}
        >
          <div className="bg-gray-100">
            <Editor
              editorState={content}
              onEditorStateChange={(state) => setContent(state)}
              localization={{ locale: 'zh_tw' }}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  alt: { present: true, mandatory: true },
                  previewImage: true,
                  defaultSize: { width: '100%', height: 'auto' },
                },
              }}
            />
          </div>
        </antd.Form.Item>

        <antd.Form.Item
          name="eventDate"
          label="活動時間"
          rules={[{ required: true, message: '請輸入活動時間' }]}
        >
          <antd.DatePicker />
        </antd.Form.Item>

        <antd.Upload
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          multiple={true}
          showUploadList={{ showPreviewIcon: false }}
          accept={'.bmp,.jpg,.jpeg,.png,.gif'}
        >
          {fileList.length >= 5 ? null : (
            <>
              <PlusOutlined />
              <div>{`還可上傳${5 - fileList.length}張`}</div>
            </>
          )}
        </antd.Upload>

        <antd.Form.Item name="hidden" label="是否隱藏">
          <antd.Switch defaultChecked={item?.hidden} />
        </antd.Form.Item>

        <antd.Form.Item className="text-center">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};
