/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';
import dayjs from 'dayjs';

import { AppContext } from '../AppContext';

const AuditLogsPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<AuditLog[]>([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const init = async (page = currentPage, action?: string) => {
    setSpinning(true);
    const qstring = {
      sort: 'createdAt:desc',
      pagination: { page, pageSize },
      filters: action ? { action: { $contains: action } } : undefined,
    };
    const data = await appCtx.fetch(
      'get',
      '/api/audit-logs?' + qs.stringify(qstring),
    );

    if (data) {
      console.log(data);
      const temp = data.data.map((item: any) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });

      console.log(temp);
      setDataSource(temp);
      setTotal(data.meta.pagination.total);
      setCurrentPage(data.meta.pagination.page);
    }

    setSpinning(false);
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  interface AuditLog {
    key: string;
    name: string;
    address: string;
  }

  const columns: ColumnsType<AuditLog> = [
    {
      title: '部門',
      align: 'center',
      dataIndex: 'accountDepartment',
    },
    {
      title: '員工編號',
      align: 'center',
      dataIndex: 'accountEmployeeNo',
    },
    {
      title: '人員名稱',
      align: 'center',
      dataIndex: 'accountName',
    },
    {
      title: '手機',
      align: 'center',
      dataIndex: 'accountPhone',
    },
    {
      title: '電子信箱',
      align: 'center',
      dataIndex: 'accountEmail',
    },
    {
      title: '操作動作',
      align: 'center',
      dataIndex: 'action',
    },
    {
      title: '操作項目的概要',
      align: 'center',
      dataIndex: 'targetSummary',
    },
    {
      title: '創建日期',
      align: 'center',
      render: (item) => (
        <>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
      ),
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Input.Search
          placeholder="搜尋操作動作"
          onSearch={(value) => init(currentPage, value)}
          style={{ width: 200 }}
        />
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
      />
    </antd.Spin>
  );
};

export { AuditLogsPage };
