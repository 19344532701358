/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import qs from 'qs';

import { AppContext } from '../../AppContext';

interface RoomPriceContextProps {
  spinning: boolean;
  setSpinning: React.Dispatch<React.SetStateAction<boolean>>;

  roomCollectionID: number | undefined;
  setRoomCollectionID: React.Dispatch<React.SetStateAction<number | undefined>>;

  customPrice: any[] | undefined;
  date: moment.Moment;
  setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;

  roomPrice: number[];
  setRoomPrice: React.Dispatch<React.SetStateAction<number[]>>;
  deposit: number[];
  setDeposit: React.Dispatch<React.SetStateAction<number[]>>;
  addingUserPrice: number[];
  setAddingUserPrice: React.Dispatch<React.SetStateAction<number[]>>;
  bigBedPrice: number[];
  setBigBedPrice: React.Dispatch<React.SetStateAction<number[]>>;
  smallBedPrice: number[];
  setSmallBedPrice: React.Dispatch<React.SetStateAction<number[]>>;

  getCustom: () => Promise<void>;
  getDefault: () => Promise<void>;
}

const RoomPriceContext = React.createContext<RoomPriceContextProps>(undefined!);

interface RoomPriceProviderProps {
  children: React.ReactNode;
}

const RoomPriceProvider = ({ children }: RoomPriceProviderProps) => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [roomCollectionID, setRoomCollectionID] = React.useState<number>();

  // 預設價格
  const [roomPrice, setRoomPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [deposit, setDeposit] = React.useState<number[]>([0, 0, 0, 0, 0, 0, 0]);
  const [addingUserPrice, setAddingUserPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [bigBedPrice, setBigBedPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [smallBedPrice, setSmallBedPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);

  // 設定價格
  const [customPrice, setCustomPrice] = React.useState<any[]>();

  // 月曆當前日期
  const [date, setDate] = React.useState<moment.Moment>(moment());
  /////////////////////////////////////////////////////

  // 取得客製化時間
  const getCustom = async () => {
    // console.log(date.format('YYYY-MM-DD'));
    setSpinning(true);
    const qstring = {
      filters: {
        collection: { id: { $eq: roomCollectionID } },
        date: {
          $gte: date.startOf('month').format('YYYY-MM-DD'),
          $lte: date.endOf('month').format('YYYY-MM-DD'),
        },
      },
      pagination: { pageSize: 43 },
    };
    const data = await appCtx.fetch(
      'get',
      '/api/room-collection-pricings?' + qs.stringify(qstring),
    );
    if (data) {
      const temp = data.data.map((item: any) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });
      setCustomPrice(temp);
    }
    setSpinning(false);
  };

  // 取得預設
  const getDefault = async () => {
    const data = await appCtx.fetch(
      'get',
      '/api/room-collections/' + roomCollectionID,
    );
    if (data) {
      setRoomPrice(data.data.attributes.roomPrice);
      setDeposit(data.data.attributes.deposit);
      setAddingUserPrice(data.data.attributes.addingUserPrice);
      setBigBedPrice(data.data.attributes.bigBedPrice);
      setSmallBedPrice(data.data.attributes.smallBedPrice);
    }
  };

  React.useEffect(() => {
    if (roomCollectionID) {
      getCustom();
    }
  }, [date]);

  React.useEffect(() => {
    if (roomCollectionID) {
      getCustom();
      getDefault();
    }
  }, [roomCollectionID]);

  /////////////////////////////////////////////////////

  return (
    <RoomPriceContext.Provider
      value={{
        spinning,
        setSpinning,
        roomCollectionID,
        setRoomCollectionID,

        customPrice,
        date,
        setDate,

        roomPrice,
        setRoomPrice,
        deposit,
        setDeposit,
        addingUserPrice,
        setAddingUserPrice,
        bigBedPrice,
        setBigBedPrice,
        smallBedPrice,
        setSmallBedPrice,

        getCustom,
        getDefault,
      }}
    >
      {children}
    </RoomPriceContext.Provider>
  );
};

export { RoomPriceContext, RoomPriceProvider };
