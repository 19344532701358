import React from 'react';
import * as antd from 'antd';
import moment from 'moment';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const AEEntryOrder = ({
  item,
  onSuccess,
}: {
  item?: any;
  onSuccess: () => void;
}) => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);

  const onFinish = async (values: any) => {
    setSpinning(true);

    let data: any = null;

    if (item?.id) {
      data = await appCtx.fetch('put', '/api/entry-orders/' + item.id, {
        data: {
          name: values.name,
          date: values.date,
          kind: values.kind,
          contactName: values.contactName,
          phone: values.phone,
          count: values.count,
          note: values.note,
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/entry-orders', {
        data: {
          name: values.name,
          date: values.date,
          kind: values.kind,
          contactName: values.contactName,
          phone: values.phone,
          count: values.count,
          note: values.note,
        },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.id ? '編輯' : '新增') + '入園訂單成功',
      );
      onSuccess();
    }
    setSpinning(false);
  };

  return (
    <antd.Spin spinning={spinning}>
      <antd.Form
        initialValues={{
          name: item?.name,
          date: item ? moment(item.date) : undefined,
          kind: item?.kind,
          contactName: item?.contactName,
          phone: item?.phone,
          count: item?.count,
          note: item?.note,
        }}
        onFinish={onFinish}
      >
        <h5 className="mb-4 font-weight-bold">{item ? '編輯' : '新增'}</h5>

        <antd.Form.Item
          name="name"
          label="名稱"
          rules={[{ required: true, message: '請輸入團體名稱' }]}
          className="sm:mb-2"
        >
          <antd.Input placeholder="請輸入團體名稱" />
        </antd.Form.Item>

        <antd.Form.Item
          name="date"
          label="日期"
          rules={[{ required: true, message: '請輸入日期' }]}
          className="sm:mb-2"
        >
          <antd.DatePicker />
        </antd.Form.Item>

        <antd.Form.Item
          name="kind"
          label="類型"
          rules={[{ required: true, message: '請輸入類型' }]}
          className="sm:mb-2"
        >
          <antd.Input placeholder="請輸入類型" />
        </antd.Form.Item>

        <antd.Form.Item
          name="contactName"
          label="聯絡人"
          rules={[{ required: true, message: '請輸入聯絡人' }]}
          className="sm:mb-2"
        >
          <antd.Input placeholder="請輸入聯絡人" />
        </antd.Form.Item>

        <antd.Form.Item
          name="phone"
          label="電話"
          rules={[{ required: true, message: '請輸入電話' }]}
          className="sm:mb-2"
        >
          <antd.Input placeholder="請輸入電話" />
        </antd.Form.Item>

        <antd.Form.Item
          name="count"
          label="人數"
          rules={[{ required: true, message: '請輸入人數' }]}
          className="sm:mb-2"
        >
          <antd.InputNumber placeholder="請輸入人數" />
        </antd.Form.Item>

        <antd.Form.Item name="note" label="備註" className="sm:mb-2">
          <antd.Input.TextArea rows={4} placeholder="請輸入備註" />
        </antd.Form.Item>

        <antd.Form.Item className="text-center sm:mb-2">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};
