/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as antd from 'antd';
import moment from 'moment';

import { AppContext } from '../AppContext';
import { RoomPriceContext } from './utils/RoomPriceContext';
import { Notification } from '../components/Notification';

const DefaultUpdate = () => {
  const appCtx = React.useContext(AppContext);
  const roomPriceCtx = React.useContext(RoomPriceContext);

  // 預設價格
  const [roomPrice, setRoomPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [deposit, setDeposit] = React.useState<number[]>([0, 0, 0, 0, 0, 0, 0]);
  const [addingUserPrice, setAddingUserPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [bigBedPrice, setBigBedPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [smallBedPrice, setSmallBedPrice] = React.useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);

  React.useEffect(() => {
    setRoomPrice(roomPriceCtx.roomPrice);
    setDeposit(roomPriceCtx.deposit);
    setAddingUserPrice(roomPriceCtx.addingUserPrice);
    setBigBedPrice(roomPriceCtx.bigBedPrice);
    setSmallBedPrice(roomPriceCtx.smallBedPrice);
  }, [
    roomPriceCtx.roomPrice,
    roomPriceCtx.deposit,
    roomPriceCtx.addingUserPrice,
    roomPriceCtx.bigBedPrice,
    roomPriceCtx.smallBedPrice,
  ]);

  const updateDefaultPricing = async (values: any) => {
    roomPriceCtx.setSpinning(true);

    const data = await appCtx.fetch(
      'put',
      '/api/room-collections/' + roomPriceCtx.roomCollectionID,
      {
        data: {
          roomPrice,
          deposit,
          addingUserPrice,
          bigBedPrice,
          smallBedPrice,
        },
      },
    );

    if (data) {
      Notification.add('success', '設定成功');
      await roomPriceCtx.getDefault();
    }
    roomPriceCtx.setSpinning(false);
  };

  return (
    <>
      <div className="flex justify-end mb-3">
        <antd.Button type="primary" onClick={updateDefaultPricing}>
          設定預設價格
        </antd.Button>
      </div>

      <div className="flex mb-3">
        <div className="w-1/6">星期一到星期四: </div>
        <div className="flex w-5/6 gap-2">
          <antd.InputNumber
            min={0}
            addonBefore="房價"
            value={roomPrice[1]}
            onChange={(input) => {
              const temp = [...roomPrice];
              const ninput = input ?? 0;
              temp[1] = ninput;
              temp[2] = ninput;
              temp[3] = ninput;
              temp[4] = ninput;
              setRoomPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="訂金"
            value={deposit[1]}
            onChange={(input) => {
              const temp = [...deposit];
              const ninput = input ?? 0;
              temp[1] = ninput;
              temp[2] = ninput;
              temp[3] = ninput;
              temp[4] = ninput;
              setDeposit(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加人價"
            value={addingUserPrice[1]}
            onChange={(input) => {
              const temp = [...addingUserPrice];
              const ninput = input ?? 0;
              temp[1] = ninput;
              temp[2] = ninput;
              temp[3] = ninput;
              temp[4] = ninput;
              setAddingUserPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加大床"
            value={bigBedPrice[1]}
            onChange={(input) => {
              const temp = [...bigBedPrice];
              const ninput = input ?? 0;
              temp[1] = ninput;
              temp[2] = ninput;
              temp[3] = ninput;
              temp[4] = ninput;
              setBigBedPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加小床"
            value={smallBedPrice[1]}
            onChange={(input) => {
              const temp = [...smallBedPrice];
              const ninput = input ?? 0;
              temp[1] = ninput;
              temp[2] = ninput;
              temp[3] = ninput;
              temp[4] = ninput;
              setSmallBedPrice(temp);
            }}
          />
        </div>
      </div>

      <div className="flex mb-3">
        <div className="w-1/6">星期五和星期日: </div>
        <div className="flex w-5/6 gap-2">
          <antd.InputNumber
            min={0}
            addonBefore="房價"
            value={roomPrice[0]}
            onChange={(input) => {
              const temp = [...roomPrice];
              const ninput = input ?? 0;
              temp[0] = ninput;
              temp[5] = ninput;
              setRoomPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="訂金"
            value={deposit[0]}
            onChange={(input) => {
              const temp = [...deposit];
              const ninput = input ?? 0;
              temp[0] = ninput;
              temp[5] = ninput;
              setDeposit(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加人價"
            value={addingUserPrice[0]}
            onChange={(input) => {
              const temp = [...addingUserPrice];
              const ninput = input ?? 0;
              temp[0] = ninput;
              temp[5] = ninput;
              setAddingUserPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加大床"
            value={bigBedPrice[0]}
            onChange={(input) => {
              const temp = [...bigBedPrice];
              const ninput = input ?? 0;
              temp[0] = ninput;
              temp[5] = ninput;
              setBigBedPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加小床"
            value={smallBedPrice[0]}
            onChange={(input) => {
              const temp = [...smallBedPrice];
              const ninput = input ?? 0;
              temp[0] = ninput;
              temp[5] = ninput;
              setSmallBedPrice(temp);
            }}
          />
        </div>
      </div>

      <div className="flex mb-3">
        <div className="w-1/6">星期六: </div>
        <div className="flex w-5/6 gap-2">
          <antd.InputNumber
            min={0}
            addonBefore="房價"
            value={roomPrice[6]}
            onChange={(input) => {
              const temp = [...roomPrice];
              const ninput = input ?? 0;
              temp[6] = ninput;
              setRoomPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="訂金"
            value={deposit[6]}
            onChange={(input) => {
              const temp = [...deposit];
              const ninput = input ?? 0;
              temp[6] = ninput;
              setDeposit(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加人價"
            value={addingUserPrice[6]}
            onChange={(input) => {
              const temp = [...addingUserPrice];
              const ninput = input ?? 0;
              temp[6] = ninput;
              setAddingUserPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加大床"
            value={bigBedPrice[6]}
            onChange={(input) => {
              const temp = [...bigBedPrice];
              const ninput = input ?? 0;
              temp[6] = ninput;
              setBigBedPrice(temp);
            }}
          />
          <antd.InputNumber
            min={0}
            addonBefore="加小床"
            value={smallBedPrice[6]}
            onChange={(input) => {
              const temp = [...smallBedPrice];
              const ninput = input ?? 0;
              temp[6] = ninput;
              setSmallBedPrice(temp);
            }}
          />
        </div>
      </div>
    </>
  );
};

const BatchUpdate = () => {
  const appCtx = React.useContext(AppContext);
  const roomPriceCtx = React.useContext(RoomPriceContext);

  const [startDate, setStartDate] = React.useState<moment.Moment>();
  const [endDate, setEndDate] = React.useState<moment.Moment>();

  const [roomPrice, setRoomPrice] = React.useState<number>();
  const [deposit, setDeposit] = React.useState<number>();
  const [addingUserPrice, setAddingUserPrice] = React.useState<number>();
  const [bigBedPrice, setBigBedPrice] = React.useState<number>();
  const [smallBedPrice, setSmallBedPrice] = React.useState<number>();

  const updateBatchPricing = async () => {
    roomPriceCtx.setSpinning(true);
    const data = await appCtx.fetch(
      'post',
      '/api/room-collection-pricings/batchUpdating',
      {
        collection: roomPriceCtx.roomCollectionID,
        startDate: startDate?.format('YYYY-MM-DD'),
        endDate: endDate?.format('YYYY-MM-DD'),
        roomPrice,
        deposit,
        addingUserPrice,
        bigBedPrice,
        smallBedPrice,
      },
    );

    if (data) {
      Notification.add('success', '修改批次設定價格成功');
      await roomPriceCtx.getCustom();
    }

    setStartDate(undefined);
    setEndDate(undefined);
    setRoomPrice(undefined);
    setDeposit(undefined);
    setAddingUserPrice(undefined);
    setBigBedPrice(undefined);
    setSmallBedPrice(undefined);

    roomPriceCtx.setSpinning(false);
  };
  return (
    <>
      <div className="flex justify-end mb-3">
        <antd.DatePicker.RangePicker
          value={[startDate || null, endDate || null]}
          onChange={(_, s) => {
            setStartDate(moment(s[0]));
            setEndDate(moment(s[1]));
          }}
        />
        <div className="flex-1" />
        <antd.Button type="primary" onClick={updateBatchPricing}>
          批次設定價格
        </antd.Button>
      </div>

      <div className="flex gap-4">
        <antd.InputNumber
          min={0}
          addonBefore="房價"
          value={roomPrice}
          onChange={(input) => setRoomPrice(input ?? 0)}
        />
        <antd.InputNumber
          min={0}
          addonBefore="訂金"
          value={deposit}
          onChange={(input) => setDeposit(input ?? 0)}
        />
        <antd.InputNumber
          min={0}
          addonBefore="加人價"
          value={addingUserPrice}
          onChange={(input) => setAddingUserPrice(input ?? 0)}
        />
        <antd.InputNumber
          min={0}
          addonBefore="加大床"
          value={bigBedPrice}
          onChange={(input) => setBigBedPrice(input ?? 0)}
        />
        <antd.InputNumber
          min={0}
          addonBefore="加小床"
          value={smallBedPrice}
          onChange={(input) => setSmallBedPrice(input ?? 0)}
        />
      </div>
    </>
  );
};

const Cell = ({ value }: { value: moment.Moment }) => {
  const appCtx = React.useContext(AppContext);
  const roomPriceCtx = React.useContext(RoomPriceContext);

  // 確定這個日期有沒有被設定過
  const [id, setID] = React.useState<number>();

  // 這個日期相關資料
  const [data, setData] = React.useState<any>({
    pricing: 0,
    deposit: 0,
    addUser: 0,
    bigBed: 0,
    smallBed: 0,
  });

  React.useEffect(() => {
    setData({
      pricing: roomPriceCtx.roomPrice[value.weekday()],
      deposit: roomPriceCtx.deposit[value.weekday()],
      addUser: roomPriceCtx.addingUserPrice[value.weekday()],
      bigBed: roomPriceCtx.bigBedPrice[value.weekday()],
      smallBed: roomPriceCtx.smallBedPrice[value.weekday()],
    });
    const temp = roomPriceCtx.customPrice?.filter(
      (price) =>
        moment(price.date).format('YYYY-MM-DD') === value.format('YYYY-MM-DD'),
    );
    console.log(temp);
    if (temp && temp.length > 0) {
      setData({
        pricing: temp[0].roomPrice,
        deposit: temp[0].deposit,
        addUser: temp[0].addingUserPrice,
        bigBed: temp[0].bigBedPrice,
        smallBed: temp[0].smallBedPrice,
      });
      setID(temp[0].id);
    } else {
      setID(undefined);
    }
  }, [
    roomPriceCtx.customPrice,
    roomPriceCtx.roomPrice,
    roomPriceCtx.deposit,
    roomPriceCtx.addingUserPrice,
    roomPriceCtx.bigBedPrice,
    roomPriceCtx.smallBedPrice,
  ]);

  const changePricing = async () => {
    antd.Modal.confirm({
      title: '確認',
      icon: <i />,
      content: `是否修改 ${moment(value).format('YYYY年MM月DD日')} 的房價?`,
      okText: '確認',
      cancelText: '取消',
      onOk: async () => {
        roomPriceCtx.setSpinning(true);
        let res = null;
        if (id) {
          res = await appCtx.fetch(
            'put',
            '/api/room-collection-pricings/' + id,
            {
              data: {
                roomPrice: data.pricing,
                deposit: data.deposit,
                addingUserPrice: data.addUser,
                bigBedPrice: data.bigBed,
                smallBedPrice: data.smallBed,
              },
            },
          );
        } else {
          res = await appCtx.fetch('post', '/api/room-collection-pricings', {
            data: {
              collection: roomPriceCtx.roomCollectionID,
              date: value.format('YYYY-MM-DD'),
              roomPrice: data.pricing,
              deposit: data.deposit,
              addingUserPrice: data.addUser,
              bigBedPrice: data.bigBed,
              smallBedPrice: data.smallBed,
            },
          });
        }

        if (res) {
          Notification.add('success', '修改房價成功');
          await roomPriceCtx.getCustom();
        }
        roomPriceCtx.setSpinning(false);
      },
    });
  };

  // 如果不是這個月份要disable
  if (value.month() !== roomPriceCtx.date.month()) {
    return (
      <div className="p-1 m-1 bg-gray-100">
        <div className="">{value.format('DD')}</div>
        <antd.InputNumber addonBefore="房價" disabled />
        <antd.InputNumber addonBefore="訂金" disabled />
        <antd.InputNumber addonBefore="加人價" disabled />
        <antd.InputNumber addonBefore="加大床" disabled />
        <antd.InputNumber addonBefore="加小床" disabled />
        <antd.Button className="w-full mt-2" disabled>
          修改
        </antd.Button>
      </div>
    );
  }

  const isToday = moment().format('YYYY-MM-DD') === value.format('YYYY-MM-DD');

  return (
    <div
      className={`m-1 p-1 border-solid border border-${
        isToday ? 'red' : 'black'
      }-500`}
    >
      <div>{value.format('DD')}</div>
      <antd.InputNumber
        addonBefore="房價"
        value={data.pricing}
        onChange={(input) => setData({ ...data, pricing: input })}
      />
      <antd.InputNumber
        addonBefore="訂金"
        value={data.deposit}
        onChange={(input) => setData({ ...data, deposit: input })}
      />
      <antd.InputNumber
        addonBefore="加人價"
        value={data.addUser}
        onChange={(input) => setData({ ...data, addUser: input })}
      />
      <antd.InputNumber
        addonBefore="加大床"
        value={data.bigBed}
        onChange={(input) => setData({ ...data, bigBed: input })}
      />
      <antd.InputNumber
        addonBefore="加小床"
        value={data.smallBed}
        onChange={(input) => setData({ ...data, smallBed: input })}
      />
      <antd.Button
        className="w-full mt-2"
        type={`${id ? 'dashed' : 'primary'}`}
        onClick={changePricing}
      >
        修改
      </antd.Button>
    </div>
  );
};

export const EditRoomPricing = ({ item }: { item: any }) => {
  const roomPriceCtx = React.useContext(RoomPriceContext);

  React.useEffect(() => {
    roomPriceCtx.setRoomCollectionID(item.id);
  }, []);

  return (
    <antd.Spin spinning={roomPriceCtx.spinning}>
      <h1 className="mb-4 font-weight-bold">{item.name}</h1>

      <DefaultUpdate />
      <antd.Divider />

      <BatchUpdate />
      <antd.Divider />

      {roomPriceCtx.customPrice && (
        <antd.Calendar
          onPanelChange={(date) => roomPriceCtx.setDate(date)}
          dateFullCellRender={(value: moment.Moment) => <Cell value={value} />}
          mode="month"
        />
      )}
    </antd.Spin>
  );
};
