/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';

import { AppContext } from '../AppContext';

import { AESingleMeals } from '../modals/AESingleMeals';
import { DangerButton } from '../components/DangerButton';
import moment from 'moment';

export interface SingleMeal {
  key: string;
  categoryName: string;
  categoryId: number;
  name: string;
  unit: string;
  price: number;
  images: { id: number; url: string }[];
}

interface MealCount {
  key: number;
  date: string;
  count: number;
}

const SubTable = ({ id, expanded }: { id: string; expanded: boolean }) => {
  const appCtx = useContext(AppContext);
  const [dataSource, setDataSource] = useState<MealCount[]>([]);
  const [spinning, setSpinning] = useState(false);

  const init = useCallback(async () => {
    setSpinning(true);
    const info = {
      startDate: moment(),
      endDate: moment().add(50, 'd'),
    };

    const result = await appCtx.fetch(
      'post',
      `/api/single-meal/search/${id}`,
      // info,
    );

    if (result) {
      let data = result.data;

      const dataWithKeys = data.map(
        (item: { date: string; count: number }, i: number): MealCount => ({
          key: i,
          date: item.date,
          count: item.count,
        }),
      );
      setDataSource(dataWithKeys);
      setSpinning(false);
    }
  }, [appCtx]);

  useEffect(() => {
    if (appCtx.jwt && expanded) {
      init();
    }
  }, [appCtx.jwt, init, expanded]);

  const columns: ColumnsType<MealCount> = [
    {
      title: '日期',
      dataIndex: 'date',
      align: 'center',
      width: '50%',
    },
    {
      title: '數量',
      dataIndex: 'count',
      align: 'center',
      width: '50%',
    },
  ];
  return (
    <antd.Spin spinning={spinning}>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ position: ['topRight'] }}
        className="float-right w-11/12"
        bordered={true}
      />
    </antd.Spin>
  );
};

const SingleMealsPage = () => {
  const appCtx = React.useContext(AppContext);

  const [dataSource, setDataSource] = React.useState<SingleMeal[]>([]); //coulmns data
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const init = async (page = currentPage, name?: string) => {
    setSpinning(true);
    const qstring = {
      sort: 'createdAt:desc',
      populate: ['meal_category', 'images'],
      pagination: { page, pageSize },
      filters: name ? { name: { $contains: name } } : undefined,
    };
    const data = await appCtx.fetch(
      'get',
      '/api/single-meals?' + qs.stringify(qstring),
    );
    const temp: SingleMeal[] = data.data.map((item: any): SingleMeal => {
      return {
        key: item.id,
        categoryName: item.attributes.meal_category.data.attributes.name,
        categoryId: item.attributes.meal_category.data.id,
        name: item.attributes.name,
        unit: item.attributes.unit,
        price: item.attributes.pricing,
        images: item.attributes.images.data
          ? item.attributes.images.data.map((image: any) => ({
              url: image.attributes.url,
              id: image.id,
            }))
          : [],
      };
    });

    console.log(temp);
    setDataSource(temp);
    setTotal(data.meta.pagination.total);
    setCurrentPage(data.meta.pagination.page);
    setSpinning(false);
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  const delSingleMeal = async (id: number) => {
    await appCtx.fetch('delete', `/api/single-meals/${id}`);
    init();
  };

  const columns: ColumnsType<SingleMeal> = [
    {
      title: '類別',
      align: 'center',
      render: (item: SingleMeal) => <>{item.categoryName}</>,
    },
    {
      title: '名稱',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '單位',
      align: 'center',
      dataIndex: 'unit',
    },
    {
      title: '價格',
      align: 'center',
      dataIndex: 'price',
    },
    {
      title: '圖片',
      align: 'center',
      render: (item: SingleMeal) => (
        <div className="flex items-center justify-center space-x-3">
          <antd.Image.PreviewGroup>
            {item.images.map((obj) => (
              <antd.Image width={80} src={obj.url} key={obj.id} />
            ))}
            {/* {item.images?.data &&
              item.images.data.map((image: any) => {
                return <antd.Image width={80} src={image.attributes.url} />;
              })} */}
          </antd.Image.PreviewGroup>
        </div>
      ),
    },
    {
      title: '操作',
      align: 'center',
      width: 180,
      render: (item) => (
        <>
          <antd.Button
            className="mr-3"
            type="primary"
            onClick={() =>
              appCtx.setModal(<AESingleMeals item={item} onSuccess={init} />)
            }
          >
            編輯
          </antd.Button>
          <DangerButton
            title="刪除"
            message="確定要刪除活動紀錄?"
            onClick={() => delSingleMeal(item.key)}
          />
        </>
      ),
    },
  ];

  const expandedRowRender = (
    record: SingleMeal,
    index: number,
    indent: number,
    expanded: boolean,
  ) => {
    // const result = await appCtx.fetch(
    //   'post',
    //   `/api/single-meal/search/${record.key}`,
    // );
    // console.log(result);
    const columns = [
      { title: 'Date', dataIndex: 'date', key: 'date' },
      { title: 'Name', dataIndex: 'name', key: 'name' },
    ];

    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i,
        date: '2014-12-24 23:12:00',
        name: 'This is production name',
        upgradeNum: 'Upgraded: 56',
      });
    }
    return (
      // <antd.Table columns={columns} dataSource={data} pagination={false} />
      <SubTable id={record.key} expanded={expanded} />
    );
  };

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Input.Search
          placeholder="搜尋名稱"
          onSearch={(value) => init(currentPage, value)}
          style={{ width: 200 }}
        />

        <div className="flex-1" />
        <antd.Button
          type="primary"
          onClick={() => appCtx.setModal(<AESingleMeals onSuccess={init} />)}
        >
          新增
        </antd.Button>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
        expandable={{ expandedRowRender }}
      />
    </antd.Spin>
  );
};

export { SingleMealsPage };
