import { Modal, Button, Form, Input } from 'antd';

import { emptyVendor, Vendor } from '../pages/Accounting/types';

interface UpdateVendorProps {
  visible: boolean;
  handleOk: (values: Vendor) => void;
  handleCancel: () => void;
  vendor: Vendor | null;
}

const UpdateVendor = ({
  visible,
  handleOk,
  handleCancel,
  vendor,
}: UpdateVendorProps) => {
  const [form] = Form.useForm();
  const initObj = vendor
    ? {
        initialValues: { ...vendor },
      }
    : { initialValues: { ...emptyVendor } };

  const onConfirmModal = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        console.log(values);
        handleOk(values);
      })
      .catch(({ values, errorFields, outOfDate }) => {
        setTimeout(() => {
          const arr = errorFields.map((field: { name: string }) => ({
            name: field.name,
            errors: [],
          }));
          form.setFields(arr);
        }, 2000);
      });
  };
  return (
    <Modal
      visible={visible}
      title={
        <h1 className="text-lg text-center">
          {vendor ? '修改公司資訊' : '新增公司資訊'}
        </h1>
      }
      //   okText="Create"
      //   cancelText="Cancel"
      footer={[
        <div className="flex justify-center gap-6" key={1}>
          <Button key="back" onClick={handleCancel} className="px-large">
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            className="px-large"
            onClick={onConfirmModal}
          >
            確定
          </Button>
        </div>,
      ]}
      onCancel={handleCancel}
      onOk={onConfirmModal}
      closable={false}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        requiredMark={true}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        // initialValues={{ ...vendor }}
        {...initObj}
      >
        <Form.Item
          name="key"
          label="編號"
          labelCol={{ span: 2 }}
          className="mb-3"
        >
          <p className="mb-0">{vendor?.key}</p>
        </Form.Item>
        <Form.Item
          name="name"
          label="廠商"
          rules={[
            {
              required: true,
              message: '請輸入廠商名稱',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入廠商名稱" />
        </Form.Item>
        <Form.Item
          name="shortName"
          label="廠商簡稱"
          rules={[
            {
              required: true,
              message: '請輸入廠商簡稱',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入廠商簡稱" />
        </Form.Item>
        <Form.Item
          name="uniformNumbers"
          label="統一編號"
          rules={[
            {
              required: true,
              message: '請輸入廠商統一編號',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入廠商統一編號" />
        </Form.Item>
        <Form.Item
          name="address"
          label="公司地址"
          rules={[
            {
              required: true,
              message: '請輸入公司地址',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入公司地址" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="電話"
          rules={[
            {
              required: true,
              message: '請輸入電話',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入電話" />
        </Form.Item>
        <Form.Item name="fax" label="傳真" className="mb-3">
          <Input placeholder="輸入傳真" />
        </Form.Item>
        <Form.Item
          name="contactPerson1"
          label="聯絡人1"
          rules={[
            {
              required: true,
              message: '請輸入聯絡人1',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入聯絡人1" />
        </Form.Item>
        <Form.Item name="contactPerson2" label="聯絡人2" className="mb-3">
          <Input placeholder="輸入聯絡人2" />
        </Form.Item>
        <Form.Item
          name="email"
          label="信箱"
          rules={[
            {
              required: true,
              message: '請輸入信箱',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入信箱" />
        </Form.Item>
        <Form.Item
          name="bankCode"
          label="銀行代號"
          rules={[
            {
              required: true,
              message: '請輸入銀行代號',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入銀行代號" />
        </Form.Item>
        <Form.Item
          name="bankAccount"
          label="銀行帳號"
          rules={[
            {
              required: true,
              message: '請輸入銀行帳號',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入銀行帳號" />
        </Form.Item>
        <Form.Item name="note" label="備註" className="mb-3">
          <Input placeholder="輸入備註" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateVendor;
