/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';

import { AppContext } from '../AppContext';
import { AERentalEquipment } from '../modals/AERentalEquipment';
import { DangerButton } from '../components/DangerButton';

const RentalEquipmentPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<RentalEquipment[]>([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const init = async (page = currentPage, title?: string) => {
    setSpinning(true);
    const qstring = {
      sort: 'createdAt:desc',
      pagination: { page, pageSize },
    };
    const data = await appCtx.fetch(
      'get',
      '/api/rental-equipments?' + qs.stringify(qstring),
    );
    if (data) {
      const temp = data.data.map((item: any) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });

      console.log(temp);
      setDataSource(temp);
      setTotal(data.meta.pagination.total);
      setCurrentPage(data.meta.pagination.page);
      setSpinning(false);
    }
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  interface RentalEquipment {
    name: string;
    pricing: number;
    createdAt: string;
  }

  const deleteRentalEquipment = async (id: number) => {
    await appCtx.fetch('delete', `/api/rental-equipments/${id}`);
    init();
  };

  const columns: ColumnsType<RentalEquipment> = [
    { title: '名稱', align: 'center', dataIndex: 'name' },
    { title: '價格', align: 'center', dataIndex: 'pricing' },
    // {
    //   title: '創建日期',
    //   align: 'center',
    //   render: (item) => (
    //     <>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
    //   ),
    // },
    {
      title: '操作',
      align: 'center',
      width: 180,
      render: (item) => (
        <>
          <antd.Button
            className="mr-3"
            type="primary"
            onClick={() =>
              appCtx.setModal(
                <AERentalEquipment item={item} onSuccess={init} />,
              )
            }
          >
            編輯
          </antd.Button>
          <DangerButton
            title="刪除"
            message="確定要刪除?"
            onClick={() => deleteRentalEquipment(item.id)}
          />
        </>
      ),
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Input.Search
          placeholder="搜尋"
          onSearch={(value) => init(currentPage, value)}
          style={{ width: 200 }}
        />

        <div className="flex-1" />
        <antd.Button
          type="primary"
          onClick={() =>
            appCtx.setModal(<AERentalEquipment onSuccess={init} />)
          }
        >
          新增
        </antd.Button>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
      />
    </antd.Spin>
  );
};

export { RentalEquipmentPage };
