import React from 'react';
import axios from 'axios';
import * as antd from 'antd';

import { Notification } from './components/Notification';

// const FormData = require('form-data');

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.defaults.headers.common['Content-Type'] = 'application/json';
export interface AppContextProps {
  loginPage: string;
  homePage: string;
  setModal: (modal: any, width?: number) => void;

  jwt: string | null;
  setJWT: React.Dispatch<React.SetStateAction<string | null>>;
  account: string;
  setAccount: (value: string) => void;
  role: string;
  setRole: (value: string) => void;

  fetch: (
    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    url: string,
    param?: any,
  ) => Promise<any>;
  upload: (files: any[]) => Promise<number[] | null>;
  deUpload: (ids: number[]) => Promise<void>;

  login: (
    username: string,
    password: string,
    rememberMe: string,
  ) => Promise<boolean>;
  logout: () => Promise<void>;
  redirect: () => Promise<void>;
}

const AppContext = React.createContext<AppContextProps>(undefined!);

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  const [loginPage] = React.useState('/login');
  const [homePage] = React.useState('/homepage');
  const [modal, setModal] = React.useState<any>(null);
  const [modalWidth, setModalWidth] = React.useState<number>(520);

  const [jwt, setJWT] = React.useState<string | null>(null);
  const [account, setAccount] = React.useState('');
  const [role, setRole] = React.useState('');

  /////////////////////////////////////////////////////

  const fetch = async (
    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    url: string,
    param?: any,
  ) => {
    let data: any = null;

    try {
      const response = await axios({
        method,
        url,
        data: param,
        headers: { Authorization: 'Bearer ' + jwt },
      });
      console.log('response', response.data);

      data = response.data;
    } catch (error: any) {
      console.log(error.response.data);
      if (error.response.data.error.status === 401) {
        Notification.add('error', '沒有權限或是資源正在被使用');
      } else {
        Notification.add('error', error.response.data.error.message);
      }
    }

    return data;
  };

  const upload = async (files: any[]): Promise<number[] | null> => {
    try {
      if (files.length === 0) {
        return [];
      }
      let formData = new FormData();
      for (const file of files) {
        formData.append('files', file.originFileObj); //required
      }

      const { data } = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + jwt,
        },
      });

      return data.map((item: any) => item.id);
    } catch (error: any) {
      console.log(error.message);
      console.log(error.response);
      return null;
    }
  };

  const deUpload = async (ids: number[]) => {
    try {
      ids.map((id) =>
        axios.delete('/api/upload/files/' + id, {
          headers: { Authorization: 'Bearer ' + jwt },
        }),
      );
    } catch (error: any) {
      console.log(error.message);
      console.log(error.response);
    }
  };

  const login = async (
    username: string,
    password: string,
    rememberMe: string,
  ): Promise<boolean> => {
    try {
      const { data } = await axios.post('/api/auth/local', {
        identifier: username,
        password,
      });

      if (rememberMe === 'on')
        window.localStorage.setItem('auth_jwt', data.jwt);

      setJWT(data.jwt);
      setAccount(data.user.username);

      Notification.add('success', '登入成功');
      return true;
    } catch (error: any) {
      Notification.add('error', error.message);
      return false;
    }
  };

  const logout = async () => {
    window.localStorage.removeItem('auth_jwt');
    setAccount('');
    setRole('');
    setJWT(null);
    window.location.href = loginPage;
  };

  const redirect = async () => {
    try {
      const auth_jwt = window.localStorage.getItem('auth_jwt');

      if (auth_jwt) setJWT(auth_jwt);

      const { data } = await axios.get('/api/users/me', {
        headers: { Authorization: 'Bearer ' + (auth_jwt ? auth_jwt : jwt) },
      });

      if (data) {
        setAccount(data.username);
        setRole(data.rolename);
      }
    } catch (error: any) {
      if (error.response.data.error?.status !== 401) {
        Notification.add('error', error.response.data.error?.message);
      }
      setJWT(null);
      window.localStorage.removeItem('auth_jwt');
      window.location.href = '/#/login';
    }
  };

  /////////////////////////////////////////////////////

  return (
    <AppContext.Provider
      value={{
        loginPage,
        homePage,
        setModal: (modal: React.ReactNode | null, width: number = 520) => {
          setModalWidth(width);
          setModal(modal);
        },

        jwt,
        setJWT,
        account,
        setAccount,
        role,
        setRole,

        fetch,
        upload,
        deUpload,

        login,
        logout,
        redirect,
      }}
    >
      {modal && (
        <antd.Modal
          visible={modal !== null}
          onOk={() => setModal(null)}
          onCancel={() => setModal(null)}
          footer={null}
          closable={false}
          width={modalWidth}
        >
          {modal}
        </antd.Modal>
      )}

      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
