import React from 'react';
import * as antd from 'antd';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const AEMealCategories = ({
  item,
  onSuccess,
}: {
  item?: any;
  onSuccess: () => void;
}) => {
  const appCtx = React.useContext(AppContext);

  const onFinish = async (values: any) => {
    console.log(values);
    let data: any = null;

    if (item?.id) {
      data = await appCtx.fetch('put', '/api/meal-categories/' + item.id, {
        data: { name: values.name },
      });
    } else {
      data = await appCtx.fetch('post', '/api/meal-categories', {
        data: { name: values.name },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.id ? '編輯' : '新增') + '餐點類別成功',
      );
      onSuccess();
    }
  };

  return (
    <antd.Form initialValues={{ name: item?.name }} onFinish={onFinish}>
      <h5 className="font-weight-bold mb-4">{item?.id ? '編輯' : '新增'}</h5>

      <antd.Form.Item
        name="name"
        label="類別名稱"
        rules={[{ required: true, message: '請輸入類別名稱' }]}
      >
        <antd.Input placeholder="請輸入類別名稱" />
      </antd.Form.Item>

      <antd.Form.Item className="text-center">
        <antd.Button type="primary" htmlType="submit">
          確定
        </antd.Button>
      </antd.Form.Item>
    </antd.Form>
  );
};
