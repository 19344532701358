import React, { useState } from 'react';
import * as antd from 'antd';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../AppContext';
import { useIsMobile } from '../hooks/useIsMobile';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

export interface Menu {
  title: string;
  key?: string;
  component?: JSX.Element;
  subMenu?: SubMenu[];
}

export interface SubMenu {
  title: string;
  key: string;
  component: JSX.Element;
}

interface MainPageProps {
  menus: Menu[];
  title: string;
  content: JSX.Element;
}

const MainPage = ({ menus, title, content }: MainPageProps) => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const collapsible = useIsMobile();
  const [collapsed, setCollapsed] = useState(true);

  const renderHeader = () => {
    return (
      <antd.Layout.Header className="flex items-center shadow-sm z-[1] ">
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className:
              'text-white text-3xl -translate-x-full self-center place-items-center hidden sm:grid',
            onClick: () => setCollapsed(!collapsed),
          },
        )}
        <div className={collapsed ? 'block' : 'hidden'}>
          <span className="ml-2 text-white">{title}</span>
        </div>

        <div className="flex-1" />
        <antd.Popover
          placement="bottom"
          content={
            <div className="flex">
              <antd.Button type="link" danger onClick={() => appCtx.logout()}>
                登出
              </antd.Button>
            </div>
          }
        >
          <antd.Button type="link" icon={<i className="mr-2 fa fa-user" />}>
            {`使用者 : ${appCtx.account}`}
          </antd.Button>
        </antd.Popover>
      </antd.Layout.Header>
    );
  };

  const renderContent = () => {
    return (
      <antd.Layout.Content style={{ overflow: 'auto' }}>
        <div className="m-3">{content}</div>
      </antd.Layout.Content>
    );
  };

  const renderMenu = () => {
    return (
      <antd.Layout.Sider
        collapsible={collapsible}
        collapsed={collapsible ? collapsed : false}
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={0}
        trigger={null}
        style={{ overflow: 'auto' }}
        className="w-0 no-scrollbar"
      >
        <antd.Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={['operator', 'repast', 'reservation', 'accounting']}
          selectedKeys={[window.location.hash.substring(1)]}
          onClick={({ key }) => {
            history.push(key as string);
          }}
        >
          {menus
            .filter((item) => {
              // 只有營運主管看得到主管頁面
              if (item.key === 'operator' && appCtx.role !== 'Operation') {
                return false;
              }
              // 只有營運主管跟餐飲部門可以看到餐飲部門
              if (
                item.key === 'repast' &&
                appCtx.role !== 'Operation' &&
                appCtx.role !== 'Repast'
              ) {
                return false;
              }
              // 只有訂房部門跟主管可以看到訂房部門
              if (
                item.key === 'reservation' &&
                appCtx.role !== 'Operation' &&
                appCtx.role !== 'Reservation'
              ) {
                return false;
              }
              return true;
            })
            .map((menu) => {
              if (menu.subMenu) {
                return (
                  <antd.Menu.SubMenu
                    key={menu.key}
                    title={collapsed ? '' : menu.title}
                    disabled={collapsed}
                  >
                    {menu.subMenu.map((item) => (
                      <antd.Menu.Item key={item.key}>
                        <span className="d-flex align-items-center">
                          <div className="anticon"></div>
                          <span>{item.title}</span>
                        </span>
                      </antd.Menu.Item>
                    ))}
                  </antd.Menu.SubMenu>
                );
              } else {
                return (
                  <antd.Menu.Item key={menu.key}>
                    <span className="d-flex align-items-center">
                      <div className="anticon"></div>
                      <span>{menu.title}</span>
                    </span>
                  </antd.Menu.Item>
                );
              }
            })}
        </antd.Menu>
      </antd.Layout.Sider>
    );
  };

  return (
    <antd.Layout className="h-screen ">
      {renderMenu()}
      <antd.Layout className="bg-white">
        {renderHeader()}
        {renderContent()}
      </antd.Layout>
    </antd.Layout>
  );
};

export { MainPage };
