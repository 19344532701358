/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as antd from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const AEMealSet = ({
  item,
  onSuccess,
}: {
  item?: any;
  onSuccess: () => void;
}) => {
  // console.log(init);
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const handleChange = ({ fileList }: any) => setFileList(fileList.slice(0, 5));

  React.useEffect(() => {
    item?.images?.data &&
      setFileList(
        item.images.data.map((item: any) => {
          console.log(item.attributes.url);
          return { id: item.id, url: item.attributes.url };
        }),
      );

    if (item) {
      setDetails(item.details);
    }
  }, []);

  const onFinish = async (values: any) => {
    if (details.length === 0) {
      Notification.add('error', '請至少輸入一項');
      return;
    }

    if (fileList.length === 0) {
      Notification.add('error', '請至少選擇一張圖片');
      return;
    }

    setSpinning(true);

    let data: any = null;

    const ids = await appCtx.upload(fileList.filter((item) => !item.id));
    if (!ids) {
      Notification.add('error', '上傳圖片失敗');
      return;
    }

    const temp = details.filter((item) => item !== '');

    if (item?.id) {
      data = await appCtx.fetch('put', '/api/meal-sets/' + item.id, {
        data: {
          name: values.name,
          details: temp,
          images: fileList
            .filter((item) => item.id)
            .map((item) => item.id)
            .concat(ids),
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/meal-sets', {
        data: {
          name: values.name,
          details: temp,
          images: ids,
        },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.id ? '編輯' : '新增') + '餐點組合成功',
      );
      onSuccess();
    }
  };

  const [details, setDetails] = React.useState<string[]>([]);

  const delDetails = (index: number) => {
    setDetails((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <antd.Spin spinning={spinning} tip="上傳中...">
      <antd.Form initialValues={{ name: item?.name }} onFinish={onFinish}>
        <h5 className="font-weight-bold mb-4">{item?.id ? '編輯' : '新增'}</h5>

        <antd.Form.Item
          name="name"
          label="名稱"
          rules={[{ required: true, message: '請輸入名稱' }]}
        >
          <antd.Input placeholder="請輸入名稱" />
        </antd.Form.Item>

        <div className="flex justify-start mb-4">
          <antd.Button
            type="primary"
            onClick={() => setDetails((prev) => [...prev, ''])}
          >
            新增細項
          </antd.Button>
        </div>

        {details.map((item, index) => (
          <antd.Form.Item name={`detail_${index}`}>
            <antd.Input.Group compact>
              <antd.Input
                style={{ width: 'calc(100% - 63px)' }}
                placeholder="請輸入細項"
                onChange={(e) => {
                  setDetails((prev) => {
                    prev[index] = e.target.value;
                    return [...prev];
                  });
                }}
                value={details[index]}
              />
              <antd.Button danger onClick={() => delDetails(index)}>
                刪除
              </antd.Button>
            </antd.Input.Group>
          </antd.Form.Item>
        ))}

        <antd.Upload
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          multiple={true}
          showUploadList={{ showPreviewIcon: false }}
          accept={'.bmp,.jpg,.jpeg,.png,.gif'}
        >
          {fileList.length >= 5 ? null : (
            <>
              <PlusOutlined />
              <div>{`還可上傳${5 - fileList.length}張`}</div>
            </>
          )}
        </antd.Upload>

        <antd.Form.Item className="text-center">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};
