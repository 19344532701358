/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import { Button, Form, Input, Table } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import qs from 'qs';

import { AppContext } from '../../AppContext';
import UpdateVendor from '../../modals/UpdateVendor';
import { Vendor } from './types';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';

const Panel = ({
  shortName,
  email,
  contactPerson2,
  bankCode,
  bankAccount,
  fax,
  address,
}: Vendor) => (
  <div className="grid justify-start grid-cols-[repeat(4,max-content)] gap-6">
    <span>簡稱</span>
    <span>{shortName}</span>
    <span>信箱</span>
    <span>{email}</span>
    <span>聯絡人2</span>
    <span>{contactPerson2}</span>
    <span>銀行代號</span>
    <span>{bankCode}</span>
    <span>傳真</span>
    <span>{fax}</span>
    <span>銀行帳號</span>
    <span>{bankAccount}</span>
    <span>公司地址</span>
    <span>{address}</span>
  </div>
);

const VendorsPage = () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<Vendor[]>([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const [showModal, setShowModal] = React.useState(false);
  const [currentVendor, setCurrentVendor] = React.useState<Vendor | null>(null);
  const [expandedRows, setExpandedRows] = React.useState<number[]>([]);
  const [form] = useForm();
  const pageSize = 10;

  const init = async (page = currentPage, search = '') => {
    setSpinning(true);
    setExpandedRows([]);
    const pagination = {
      sort: ['hide:asc', 'updatedAt:desc'],
      pagination: { page, pageSize },
      filters: search
        ? {
            $or: [
              { name: { $containsi: search } },
              { shortName: { $containsi: search } },
              { uniformNumbers: { $containsi: search } },
              { address: { $containsi: search } },
              { phone: { $containsi: search } },
              { fax: { $containsi: search } },
              { contactPerson1: { $containsi: search } },
              { contactPerson2: { $containsi: search } },
              { email: { $containsi: search } },
              { bankCode: { $containsi: search } },
              { bankAccount: { $containsi: search } },
              { note: { $containsi: search } },
            ],
          }
        : undefined,
    };

    const result = await appCtx.fetch(
      'get',
      '/api/vendors?' + qs.stringify(pagination),
    );
    if (result) {
      const dataWithKeys = result.data.map(
        (vendor: { id: number; attributes: Vendor }) => ({
          key: vendor.id,
          name: vendor.attributes.name,
          uniformNumbers: vendor.attributes.uniformNumbers,
          contactPerson1: vendor.attributes.contactPerson1,
          phone: vendor.attributes.phone,
          shortName: vendor.attributes.shortName,
          email: vendor.attributes.email,
          contactPerson2: vendor.attributes.contactPerson2,
          bankCode: vendor.attributes.bankCode,
          fax: vendor.attributes.fax,
          bankAccount: vendor.attributes.bankAccount,
          address: vendor.attributes.address,
          hide: vendor.attributes.hide,
          note: vendor.attributes.note,
        }),
      );
      setDataSource(dataWithKeys);
      setTotal(result.meta.pagination.total);
    }
    setSpinning(false);
  };

  const handleModalOk = (values: Vendor) => {
    setShowModal(false);
    if (values.key)
      (async () => {
        await appCtx.fetch('put', `/api/vendors/${values.key}`, {
          data: values,
        });
        init(currentPage);
      })();
    else {
      (async () => {
        await appCtx.fetch('post', `/api/vendors`, { data: values });
        init(currentPage);
      })();
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  const columns: ColumnsType<Vendor> = [
    {
      title: '編號',
      align: 'left',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: '廠商',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '統一編號',
      align: 'center',
      dataIndex: 'uniformNumbers',
      key: 'uniformNumbers',
    },
    {
      title: '聯絡人1',
      align: 'left',
      dataIndex: 'contactPerson1',
      key: 'contactPerson1',
    },
    {
      title: '電話',
      align: 'left',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '',
      align: 'center',
      className: 'w-32',
      render: ({ hide, key }: Vendor) => {
        return (
          <antd.Checkbox
            checked={hide}
            onChange={async () => {
              await appCtx.fetch('put', `/api/vendors/${key}`, {
                data: {
                  hide: !hide,
                },
              });
              init(currentPage);
            }}
          >
            是否隱藏
          </antd.Checkbox>
        );
      },
    },
    {
      title: '操作',
      align: 'center',
      width: 180,
      render: (vendor: Vendor) => {
        return (
          <div className="flex justify-center gap-3">
            <antd.Button
              onClick={() => {
                history.push(`/vendors/${vendor.key}`);
              }}
            >
              查詢
            </antd.Button>
            <antd.Button
              type="primary"
              onClick={() => {
                setCurrentVendor(vendor);
                setShowModal(true);
              }}
            >
              修改
            </antd.Button>
          </div>
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="grid gap-7 mb-7">
        <Button
          className="w-fit px-large"
          onClick={() => {
            setCurrentVendor(null);
            setShowModal(true);
          }}
        >
          新增公司資料
        </Button>
        <Form
          layout="inline"
          onFinish={(values) => {
            init(1, values.keyword);
            form.resetFields();
          }}
          form={form}
        >
          <Form.Item label="查詢" name="keyword">
            <Input placeholder="請輸入關鍵字" />
          </Form.Item>
          <Form.Item>
            <Button className="px-4" htmlType="submit">
              查詢
            </Button>
          </Form.Item>
        </Form>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => {
            init(page);
            setCurrentPage(page);
          },
        }}
        expandable={{
          expandedRowRender: (record) => <Panel {...record} />,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
          expandedRowKeys: expandedRows,
          onExpand: (expanded, record) => {
            console.log(expanded, record);
            if (expanded) {
              setExpandedRows([...expandedRows, record.key]);
            } else {
              setExpandedRows(expandedRows.filter((key) => key !== record.key));
            }
          },
        }}
        scroll={{}}
      />
      <UpdateVendor
        visible={showModal}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
        vendor={currentVendor}
      />
    </antd.Spin>
  );
};

export { VendorsPage };
