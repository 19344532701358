import { AppContextProps } from '../../AppContext';
import moment from 'moment';
import { AccountingDocumentDetail } from './types';

export const addAccountingDocument = async (
  url: string,
  method: 'get' | 'post' | 'put' | 'delete' | 'patch',
  appCtx: AppContextProps,
  data: AccountingDocumentDetail[],
  payload: { name?: string; date?: string },
  type:
    | 'addADToCommon'
    | 'addADFromBlank'
    | 'addADFromCommon'
    | 'modifyAD'
    | 'modifyCommonAD',
) => {
  const dynamicKeyValue = payload.name
    ? { name: payload.name }
    : { date: payload.date };
  return await appCtx.fetch(method, url, {
    data: {
      ...dynamicKeyValue,
      details: data.map((item) => {
        const gradeString = item.isSubGrade ? 'sub_grade' : 'grade';
        const itemKey =
          (type === 'modifyAD' || type === 'modifyCommonAD') && item.key >= 1
            ? item.key
            : undefined;

        return {
          [gradeString]: item.codeId,
          id: itemKey,
          vendor: item.vendorId ? item.vendorId : undefined,
          summary: item.summary ? item.summary : undefined,
          lender: item.lender,
          debit: item.debtor,
        };
      }),
    },
  });
};
