import React, { useCallback, useRef, useEffect, useMemo, memo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import { Button, Form, Input, Table } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import qs from 'qs';

import { AppContext } from '../../AppContext';
import { DangerButton } from '../../components/DangerButton';
import AddOrUpdateSubGrade from '../../modals/AddOrUpdateSubGrade';
import { emptyGrade, Grade, MapOfSubGrades, SubGrade } from './types';
import { useForm } from 'antd/lib/form/Form';

import './GradesPage.css';

const baseURL = '/api/grades';
const subGradesBaseURL = '/api/sub-grades';

interface SubTableProps {
  dataSource: SubGrade[];
  getSubGrades: (gradeId?: number) => Promise<void>;
  setShowModal: (show: boolean) => void;
  setCurrentlySelectedGrade: (grade: Grade) => void;
  setCurrentSubGrade: (subGrade: SubGrade) => void;
  grade: Grade;
}

const SubTable = ({
  dataSource,
  getSubGrades,
  setShowModal,
  setCurrentlySelectedGrade,
  setCurrentSubGrade,
  grade,
}: SubTableProps) => {
  const ref = useRef(grade);
  const appCtx = React.useContext(AppContext);
  const subTableColumns: ColumnsType<SubGrade> = [
    {
      title: '科目編號',
      align: 'left',
      dataIndex: 'code',
      key: 'code',
      width: '12.5%',
    },
    {
      title: '科目名稱',
      align: 'left',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '操作',
      align: 'center',
      width: 120,
      render: (item: SubGrade) => {
        return (
          <div
            className="flex justify-end gap-3"
            onClick={() => setCurrentlySelectedGrade(ref.current)}
          >
            <antd.Button
              type="primary"
              onClick={() => {
                setShowModal(true);
                setCurrentSubGrade(item);
              }}
            >
              修改
            </antd.Button>
            <DangerButton
              title="移除"
              message="確定移除"
              onClick={async () => {
                const result = await appCtx.fetch(
                  'delete',
                  subGradesBaseURL + `/${item.key}?`,
                );
                getSubGrades(ref.current.key);
              }}
            />
          </div>
        );
      },
    },
    {
      width: 48,
    },
  ];

  return (
    <>
      <div className="border-gray-500 border-y-2" />
      <Table
        columns={subTableColumns}
        dataSource={dataSource}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowClassName={`subgrades-table-row`}
      />
    </>
  );
};

const MemoizedTable = memo((props: any) => {
  console.log('rendering memoized table');
  return <Table {...props} />;
});

const NotMemoizedTable = (props: any) => {
  console.log('rendering not memoized table');
  return <Table {...props} />;
};

const GradesPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<Grade[]>([]);
  const [filteredDataSource, setFilteredDataSource] = React.useState<Grade[]>(
    [],
  );
  const [showModal, setShowModal] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [expandedRows, setExpandedRows] = React.useState<number[]>([]);
  const [subGradesOfExpandedRows, setSubGradesOfExpandedRows] =
    React.useState<MapOfSubGrades>();
  const [currentlySelectedGrade, setCurrentlySelectedGrade] =
    React.useState<Grade>(emptyGrade);
  const [currentSubGrade, setCurrentSubGrade] = React.useState<SubGrade | null>(
    null,
  );

  const [form] = useForm();
  const pageSize = 250;

  const init = useCallback(
    async (mountedContainer = { isMounted: true }) => {
      setSpinning(true);
      setExpandedRows([]);
      const pagination = {
        sort: ['code:asc'],
        pagination: { page: 1, pageSize },
        // filters: search ? { code: { $containsi: search } } : undefined,
      };

      const result = await appCtx.fetch(
        'get',
        baseURL + '?' + qs.stringify(pagination),
      );

      if (result) {
        const pageCount = result.meta.pagination.pageCount;
        let data = result.data;

        for (let i = 2; i <= pageCount; i++) {
          const result = await appCtx.fetch(
            'get',
            baseURL + '?' + qs.stringify({ pagination: { page: i, pageSize } }),
          );
          data = data.concat(result.data);
        }

        const dataWithKeys = data.map(
          (item: { id: number; attributes: Grade }) => ({
            key: item.id,
            name: item.attributes.name,
            code: item.attributes.code,
          }),
        );
        if (mountedContainer.isMounted) {
          setDataSource(dataWithKeys);
          setFilteredDataSource(dataWithKeys);
          setSpinning(false);
        }
      }
    },
    [appCtx],
  );

  //if subGradeId is passed in, send a put request
  const handleModalOk = (values: SubGrade, subGradeId?: number) => {
    setModalLoading(true);
    (async () => {
      if (subGradeId)
        await appCtx.fetch('put', `${subGradesBaseURL}/${subGradeId}`, {
          data: { ...values, grade: currentlySelectedGrade.key },
        });
      else
        await appCtx.fetch('post', `${subGradesBaseURL}`, {
          data: { ...values, grade: currentlySelectedGrade.key },
        });
      setModalLoading(false);
      setShowModal(false);
      getSubGrades();
    })();
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const getSubGrades = useCallback(
    async (gradeId?: number) => {
      const id = gradeId ?? currentlySelectedGrade.key;
      setSpinning(true);
      setSubGradesOfExpandedRows({ ...subGradesOfExpandedRows, [id]: [] });
      const pagination = {
        sort: ['code:asc'],
        populate: 'sub_grades',
      };

      const result = await appCtx.fetch(
        'get',
        baseURL + `/${id}?` + qs.stringify(pagination),
      );

      if (result) {
        let data = result.data.attributes.sub_grades.data;

        const dataWithKeys = data.map(
          (item: { id: number; attributes: Grade }) => ({
            key: item.id,
            name: item.attributes.name,
            code: item.attributes.code,
          }),
        );

        setSubGradesOfExpandedRows({
          ...subGradesOfExpandedRows,
          [id]: dataWithKeys,
        });
      }
      setSpinning(false);
    },
    [appCtx, currentlySelectedGrade.key, subGradesOfExpandedRows],
  );

  useEffect(() => {
    appCtx.redirect();
  }, [appCtx]);

  useEffect(() => {
    const mountedContainer = { isMounted: true };
    if (appCtx.jwt) {
      init(mountedContainer);
    }
    return () => {
      mountedContainer.isMounted = false;
    };
  }, [appCtx.jwt, init]);

  const columns: ColumnsType<Grade> = useMemo(
    () => [
      {
        title: '科目編號',
        align: 'left',
        dataIndex: 'code',
        key: 'code',
        width: '12.5%',
      },
      {
        title: '科目名稱',
        align: 'left',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '操作',
        align: 'center',
        render: (item: Grade) => {
          return (
            <div className="flex justify-end gap-3">
              <antd.Button
                type="primary"
                onClick={() => {
                  setCurrentlySelectedGrade(item);
                  setCurrentSubGrade(null);
                  setShowModal(true);
                }}
              >
                新增
              </antd.Button>
            </div>
          );
        },
      },
      Table.EXPAND_COLUMN,
    ],
    [],
  );

  const expandable = useMemo(
    () => ({
      expandedRowClassName: () => 'subgrades-container',
      expandedRowRender: (record: Grade) => {
        return (
          <SubTable
            dataSource={
              subGradesOfExpandedRows ? subGradesOfExpandedRows[record.key] : []
            }
            getSubGrades={getSubGrades}
            grade={currentlySelectedGrade}
            setShowModal={setShowModal}
            setCurrentlySelectedGrade={setCurrentlySelectedGrade}
            setCurrentSubGrade={setCurrentSubGrade}
          />
        );
      },
      expandIcon: ({
        expanded,
        onExpand,
        record,
      }: {
        expanded: boolean;
        onExpand: (record: Grade, e: any) => void;
        record: Grade;
      }) =>
        expanded ? (
          <DownOutlined onClick={(e) => onExpand(record, e)} />
        ) : (
          <RightOutlined onClick={(e) => onExpand(record, e)} />
        ),
      expandedRowKeys: expandedRows,
      onExpand: async (expanded: boolean, record: Grade) => {
        if (expanded) {
          getSubGrades(record.key);
          setCurrentlySelectedGrade(record);
          setExpandedRows([...expandedRows, record.key]);
        } else {
          const copy = subGradesOfExpandedRows;
          if (copy) delete copy[record.key];
          setSubGradesOfExpandedRows(copy);
          setExpandedRows(expandedRows.filter((key) => key !== record.key));
        }
      },
    }),
    [
      currentlySelectedGrade,
      expandedRows,
      getSubGrades,
      subGradesOfExpandedRows,
    ],
  );

  return (
    <antd.Spin spinning={spinning}>
      <div className="mt-7 mb-7">
        <Form
          layout="inline"
          onFinish={(values) => {
            setFilteredDataSource(
              dataSource.filter((val) => val.code.includes(values.code)),
            );
          }}
          form={form}
        >
          <Form.Item label="科目查詢" name="code">
            <Input placeholder="請輸入科目編號" />
          </Form.Item>
          <Form.Item>
            <Button className="px-4" htmlType="submit">
              查詢
            </Button>
          </Form.Item>
        </Form>
      </div>
      <MemoizedTable
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        expandable={expandable}
        // scroll={{}}
      />
      {showModal && (
        <AddOrUpdateSubGrade
          visible={showModal}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
          modalLoading={modalLoading}
          gradeCode={currentlySelectedGrade.code}
          subGrade={currentSubGrade}
        />
      )}
    </antd.Spin>
  );
};

export { GradesPage };
