/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';

import { AppContext } from '../AppContext';
import { AEVipMember } from '../modals/AEVipMember';
import dayjs from 'dayjs';

const VipMemberPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<VipMember[]>([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const init = async (page = currentPage, cardNumber?: string) => {
    setSpinning(true);
    const qstring = {
      sort: 'createdAt:desc',
      pagination: { page, pageSize },
      filters: { cardNumber: { $contains: cardNumber } },
    };
    const data = await appCtx.fetch(
      'get',
      '/api/vip-members?' + qs.stringify(qstring),
    );
    if (data) {
      const temp = data.data.map((item: any) => {
        return {
          key: item.id,
          id: item.id,
          ...item.attributes,
        };
      });

      console.log(temp);
      setDataSource(temp);
      setTotal(data.meta.pagination.total);
      setCurrentPage(data.meta.pagination.page);
      setSpinning(false);
    }
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  interface VipMember {
    cardNumber: string;
    userName: string;
    phone: string;
    dateOfBirth: string;
    email: string;
    appliedAt: string;
    blocked: boolean;
    createdAt: string;
  }

  const columns: ColumnsType<VipMember> = [
    { title: '卡號', align: 'center', dataIndex: 'cardNumber' },
    { title: '姓名', align: 'center', dataIndex: 'userName' },
    { title: '手機', align: 'center', dataIndex: 'phone' },
    {
      title: '生日',
      align: 'center',
      render: (item) => {
        return <>{dayjs(item.dateOfBirth).format('YYYY-MM-DD')}</>;
      },
    },
    { title: '電子信箱', align: 'center', dataIndex: 'email' },
    {
      title: '辦卡日',
      align: 'center',
      render: (item) => {
        return <>{dayjs(item.appliedAt).format('YYYY-MM-DD')}</>;
      },
    },
    {
      title: '封鎖卡片',
      align: 'center',
      render: (item) => <antd.Switch checked={item.blocked} disabled />,
    },
    {
      title: '操作',
      align: 'center',
      width: 120,
      render: (item) => (
        <antd.Button
          type="primary"
          onClick={() =>
            appCtx.setModal(<AEVipMember item={item} onSuccess={init} />)
          }
        >
          編輯
        </antd.Button>
      ),
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Input.Search
          placeholder="搜尋卡號"
          onSearch={(value) => init(currentPage, value)}
          style={{ width: 200 }}
        />

        <div className="flex-1" />
        <antd.Button
          type="primary"
          onClick={() => appCtx.setModal(<AEVipMember onSuccess={init} />)}
        >
          新增VIP會員
        </antd.Button>
      </div>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <CardUsers cardNumber={record.cardNumber} />
          ),
        }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
        bordered
      />
    </antd.Spin>
  );
};

const CardUsers = ({ cardNumber }: { cardNumber: string }) => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<CardUser[]>([]); //coulmns data

  const init = async () => {
    console.log(cardNumber);
    setSpinning(true);
    const qstring = {
      sort: 'createdAt:desc',
      filters: { cardNumber: { $eq: cardNumber } },
    };
    const data = await appCtx.fetch(
      'get',
      '/api/vip-card-users?' + qs.stringify(qstring),
    );
    console.log(data);
    if (data) {
      const temp = data.data.map((item: any) => {
        return {
          id: item.id,
          ...item.attributes,
        };
      });

      console.log(temp);
      setDataSource(temp);
      setSpinning(false);
    }
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, []);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt]);

  interface CardUser {
    cardNumber: string;
    userName: string;
    phone: string;
    dateOfBirth: string;
    email: string;
  }

  const columns: ColumnsType<CardUser> = [
    { title: '卡號', align: 'center', dataIndex: 'cardNumber' },
    { title: '姓名', align: 'center', dataIndex: 'userName' },
    { title: '手機', align: 'center', dataIndex: 'phone' },
    {
      title: '生日',
      align: 'center',
      render: (item) => {
        return <>{dayjs(item.dateOfBirth).format('YYYY-MM-DD')}</>;
      },
    },
    { title: '電子信箱', align: 'center', dataIndex: 'email' },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <antd.Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </antd.Spin>
  );
};

export { VipMemberPage };
