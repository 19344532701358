import React from 'react';
import * as antd from 'antd';

import { AppContext } from '../AppContext';
import { Notification } from '../components/Notification';

export const AERentalEquipment = ({
  item,
  onSuccess,
}: {
  item?: any;
  onSuccess: () => void;
}) => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);

  const onFinish = async (values: any) => {
    setSpinning(true);

    let data: any = null;

    if (item?.id) {
      data = await appCtx.fetch('put', '/api/rental-equipments/' + item.id, {
        data: {
          name: values.name,
          pricing: values.pricing,
        },
      });
    } else {
      data = await appCtx.fetch('post', '/api/rental-equipments', {
        data: {
          name: values.name,
          pricing: values.pricing,
        },
      });
    }

    if (data) {
      appCtx.setModal(null);
      Notification.add(
        'success',
        (item?.id ? '編輯' : '新增') + '租用設備成功',
      );
      onSuccess();
    }
    setSpinning(false);
  };

  return (
    <antd.Spin spinning={spinning}>
      <antd.Form
        initialValues={{
          name: item?.name,
          pricing: item?.pricing,
          rentalEquipments: item?.rentalEquipments,
        }}
        onFinish={onFinish}
      >
        <h5 className="font-weight-bold mb-4">{item ? '編輯' : '新增'}</h5>

        <antd.Form.Item
          name="name"
          label="名稱"
          rules={[{ required: true, message: '請輸入名稱' }]}
        >
          <antd.Input placeholder="請輸入名稱" />
        </antd.Form.Item>

        <antd.Form.Item
          name="pricing"
          label="價格"
          rules={[{ required: true, message: '請輸入價格' }]}
        >
          <antd.InputNumber />
        </antd.Form.Item>

        <antd.Form.Item className="text-center">
          <antd.Button type="primary" htmlType="submit">
            確定
          </antd.Button>
        </antd.Form.Item>
      </antd.Form>
    </antd.Spin>
  );
};
