import React, { useState, useEffect, useCallback } from 'react';
import { Table, Input, InputNumber, Select, Form, DatePicker } from 'antd';
import * as antd from 'antd';
import qs from 'qs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import { AppContext } from '../../AppContext';
import { DangerButton } from '../../components/DangerButton';
import AddADToCommon from '../../modals/AddADToCommon';
import { AccountingDocumentDetail, dateFormat, Grade, Vendor } from './types';
import { addAccountingDocument } from './utils';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const { Option } = Select;

const randomLessThanOne = () => Math.random() * 0.99;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: keyof AccountingDocumentDetail;
  editable: boolean;
  title: any;
  inputType: 'number' | 'text' | 'code' | 'vendor';
  record: AccountingDocumentDetail;
  index: number;
  children: React.ReactNode;
  gradeOptions?: Grade[];
  setGradeOptions?: (options: Grade[]) => void;
  vendorOptions?: Vendor[];
  setVendorOptions?: (options: Vendor[]) => void;
}

const EditableCell = ({
  editable,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  gradeOptions = [],
  setGradeOptions = () => {},
  vendorOptions = [],
  setVendorOptions = () => {},
  ...restProps
}: EditableCellProps) => {
  const appCtx = React.useContext(AppContext);

  const inputNode =
    inputType === 'number' ? (
      <InputNumber />
    ) : inputType === 'text' ? (
      <Input />
    ) : (
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder={inputType === 'code' ? '輸入代碼' : '輸入簡稱'}
        filterOption={false}
        onSearch={async (val: string) => {
          const qstring =
            inputType === 'code'
              ? {
                  sort: 'code:asc',
                  pagination: { pageSize: 5 },
                  filters: val ? { code: { $startsWith: val } } : undefined,
                }
              : {
                  pagination: { pageSize: 250 },
                  filters: val
                    ? {
                        $or: [
                          { name: { $containsi: val } },
                          { shortName: { $containsi: val } },
                        ],
                      }
                    : undefined,
                };

          let result;
          if (inputType === 'code') {
            result = await appCtx.fetch(
              'get',
              `/api/grades?` + qs.stringify(qstring),
            );

            if (result.data.length < 5) {
              const result2 = await appCtx.fetch(
                'get',
                `/api/sub-grades?` + qs.stringify(qstring),
              );
              const arr = result2.data
                .slice(0, 5 - result.data.length)
                .map((item: any) => ({ ...item, isSubGrade: true }));
              result.data = result.data.concat(arr);
            }
          } else {
            result = await appCtx.fetch(
              'get',
              `/api/vendors?` + qs.stringify(qstring),
            );

            result.data = result.data
              .filter((vendor: any) => !vendor.attributes.hide)
              .slice(0, 5);
          }

          if (result) {
            if (inputType === 'code')
              setGradeOptions(
                result.data.map((val: any) => ({
                  key: val.id,
                  code: val.attributes.code,
                  name: val.attributes.name,
                  isSubGrade: !!val.isSubGrade,
                })),
              );
            else
              setVendorOptions(
                result.data.map((val: any) => ({
                  key: val.id,
                  shortName: val.attributes.shortName,
                })),
              );
          }
        }}
      >
        {inputType === 'code'
          ? gradeOptions.map((val) => (
              <Option value={val.code} key={val.key}>
                {val.code}
              </Option>
            ))
          : vendorOptions.map((val) => (
              <Option value={val.shortName} key={val.key}>
                {val.shortName}
              </Option>
            ))}
      </Select>
    );
  return (
    <td {...restProps}>
      {editable ? (
        <Form.Item
          name={`${dataIndex}-${index}`}
          style={{
            margin: 0,
          }}
          initialValue={record && record[dataIndex]}
          // rules={[{ min: 0, type: 'number' }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

type RouteParam = {
  id: string;
};

const AccountingDocumentsDetailsPage = () => {
  const appCtx = React.useContext(AppContext);

  const history = useHistory();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [meta, setMeta] = useState<{
    date: string | undefined;
    code: string;
    name: string;
  }>({ date: moment().format(dateFormat), code: '', name: '' });
  const [dataSource, setDataSource] = useState<AccountingDocumentDetail[]>([]);
  const [gradeOptions, setGradeOptions] = useState<Grade[]>([]);
  const [vendorOptions, setVendorOptions] = useState<Vendor[]>([]);
  const [showCommonlyUsedModal, setShowCommonlyUsedModal] = useState(false);
  const [canUpdateOrAdd, setCanUpdateOrAdd] = useState(false);
  const [canAddToCommon, setCanAddToCommon] = useState(false);

  const [form] = Form.useForm();
  const { id } = useParams<RouteParam>();
  const mode = (() => {
    if (id.startsWith('common')) return 'addADFromCommon';
    if (id === 'new') return 'addADFromBlank';
    return 'modifyingAD';
  })();
  const parsedId = mode === 'addADFromCommon' ? id.split('-')[1] : id;

  const totalObj = (() => {
    const lendorTotal = dataSource.reduce(
      (acc, val) => acc + (val.lender ? val.lender : 0),
      0,
    );
    const debtorTotal = dataSource.reduce(
      (acc, val) => acc + (val.debtor ? val.debtor : 0),
      0,
    );
    return { lendorTotal, debtorTotal };
  })();

  const init = useCallback(async () => {
    if (mode === 'addADFromBlank') {
      setDataSource([{ key: randomLessThanOne() }]);
      return;
    }
    setSpinning(true);
    const APITarget =
      mode === 'addADFromCommon' ? 'common-subpoenas' : 'subpoenas';
    const fragment =
      mode === 'addADFromCommon'
        ? 'common_subpoena_details'
        : 'subpoena_details';
    const result = await appCtx.fetch(
      'get',
      `/api/${APITarget}/${parsedId}?populate[${fragment}][populate]=vendor&populate[${fragment}][populate]=grade&populate[${fragment}][populate]=sub_grade`,
    );

    if (result) {
      const accountingDocumentDetailsArr =
        result.data.attributes[fragment].data;
      setMeta(
        mode === 'addADFromCommon'
          ? {
              ...meta,
              name: result.data.attributes.name,
            }
          : {
              date: result.data.attributes.date,
              code: result.data.attributes.Code,
              name: result.data.attributes.name,
            },
      );

      setDataSource([
        ...accountingDocumentDetailsArr.map((val: any) => ({
          key: val.id,
          codeId: val.attributes.grade.data
            ? val.attributes.grade.data.id
            : val.attributes.sub_grade.data?.id,
          code: val.attributes.grade.data
            ? val.attributes.grade.data.attributes.code
            : val.attributes.sub_grade.data?.attributes.code,
          codeName: val.attributes.grade.data
            ? val.attributes.grade.data.attributes.name
            : val.attributes.sub_grade.data?.attributes.name,
          vendor: val.attributes.vendor.data?.attributes.shortName,
          summary: val.attributes.summary,
          lender: parseInt(val.attributes.lender),
          debtor: parseInt(val.attributes.debit),
          isSubGrade: !val.attributes.grade.data,
        })),
        { key: randomLessThanOne() },
      ]);
    }
    setSpinning(false);
  }, [appCtx, mode, parsedId]);

  useEffect(() => {
    appCtx.redirect();
  }, [appCtx]);

  useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt, id, init]);

  useEffect(() => {
    const dsLength = dataSource.length - 1;
    const dsWOLastRow = dataSource.slice(0, dsLength);
    const lenderTotal = dsWOLastRow.reduce(
      (acc, val) => acc + (val.lender ? val.lender : 0),
      0,
    );
    const debtorTotal = dsWOLastRow.reduce(
      (acc, val) => acc + (val.debtor ? val.debtor : 0),
      0,
    );
    const isRequiredFieldsFilledIn = dsWOLastRow.every(
      (val) =>
        val.code &&
        (val.lender || val.lender === 0) &&
        (val.debtor || val.debtor === 0),
    );

    if (
      lenderTotal === debtorTotal &&
      lenderTotal !== 0 &&
      isRequiredFieldsFilledIn &&
      meta.date
    )
      setCanUpdateOrAdd(true);
    else setCanUpdateOrAdd(false);

    if (
      lenderTotal === debtorTotal &&
      lenderTotal !== 0 &&
      isRequiredFieldsFilledIn
    )
      setCanAddToCommon(true);
    else setCanAddToCommon(false);
  }, [dataSource, meta.date]);

  type Column = {};

  const columns = [
    {
      title: '',
      dataIndex: 'index',
      width: '5%',
      editable: false,
      render: (text: any, record: AccountingDocumentDetail, index: number) => (
        <div className="text-center">{index + 1}</div>
      ),
    },
    {
      title: (
        <div className="after:content-['*'] after:ml-0.5 after:text-red-500">
          代碼
        </div>
      ),
      dataIndex: 'code',
      width: '15%',
      editable: true,
    },
    {
      title: '科目',
      dataIndex: 'codeName',
      width: '15%',
    },
    {
      title: '供應商',
      dataIndex: 'vendor',
      width: '15%',
      editable: true,
    },
    {
      title: '摘要',
      dataIndex: 'summary',
      width: '20%',
      editable: true,
    },
    {
      title: (
        <div className="after:content-['*'] after:ml-0.5 after:text-red-500">
          借方
        </div>
      ),
      dataIndex: 'lender',
      width: '10%',
      editable: true,
    },
    {
      title: (
        <div className="after:content-['*'] after:ml-0.5 after:text-red-500">
          貸方
        </div>
      ),
      dataIndex: 'debtor',
      width: '10%',
      editable: true,
    },
    {
      title: '操作',
      render: (it_: any, record: AccountingDocumentDetail, index: number) => {
        return index !== dataSource.length - 1 ? (
          <DangerButton
            title="刪除"
            message="確定要傳票細項?"
            onClick={() => {
              setDataSource((dataSource) =>
                dataSource.filter((row) => record.key !== row.key),
              );
            }}
          />
        ) : null;
      },
    },
  ];

  const mergedColumns: Column[] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (
        record: AccountingDocumentDetail,
        rowIndex: number | undefined,
      ) => {
        let inputType;
        switch (col.dataIndex) {
          case 'debtor':
          case 'lender':
            inputType = 'number';
            break;
          case 'code':
            inputType = 'code';
            break;
          case 'vendor':
            inputType = 'vendor';
            break;
          default:
            inputType = 'text';
        }
        return {
          record,
          inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          editable: true,
          index: rowIndex,
          gradeOptions: gradeOptions,
          setGradeOptions: setGradeOptions,
          vendorOptions: vendorOptions,
          setVendorOptions: setVendorOptions,
        };
      },
    };
  });

  return (
    <antd.Spin spinning={spinning}>
      <div className="grid gap-6 mb-3">
        <div className="flex">
          <antd.Button
            className="px-large"
            onClick={() => {
              // switch (mode) {
              //   case 'addADFromCommon':
              //     history.push('/accounting-documents-common');
              //     break;
              //   case 'addADFromBlank':
              //   case 'modifyingAD':
              //     history.push('/accounting-documents');
              //     break;
              //   default:
              //     history.push('/accounting-documents');
              // }
              history.goBack();
            }}
          >
            返回
          </antd.Button>
          <div className="flex gap-4 ml-auto">
            <antd.Button
              className="px-large"
              onClick={async () => {
                if (mode === 'addADFromCommon') {
                  setSpinning(true);
                  const result = await addAccountingDocument(
                    `/api/common-subpoenas/${parsedId}`,
                    'put',
                    appCtx,
                    dataSource.slice(0, dataSource.length - 1),
                    { name: meta.name },
                    'modifyCommonAD',
                  );
                  await init();
                  setSpinning(false);
                } else setShowCommonlyUsedModal(true);
              }}
              disabled={!canAddToCommon}
            >
              {mode === 'addADFromCommon'
                ? '修改此常用傳票'
                : '新增至常用傳票庫'}
            </antd.Button>
            <antd.Button
              className="px-large"
              disabled={!canUpdateOrAdd}
              onClick={async () => {
                const data = dataSource.slice(0, dataSource.length - 1);
                setSpinning(true);
                let result;
                if (mode === 'addADFromBlank' || mode === 'addADFromCommon') {
                  result = await addAccountingDocument(
                    '/api/subpoenas',
                    'post',
                    appCtx,
                    data,
                    { date: meta.date },
                    mode,
                  );
                } else {
                  result = await addAccountingDocument(
                    `/api/subpoenas/${id}`,
                    'put',
                    appCtx,
                    data,
                    { date: meta.date },
                    'modifyAD',
                  );
                }
                setSpinning(false);
                if (mode === 'addADFromBlank' && result.data?.id)
                  history.push(`/accounting-documents/${result.data.id}`);
                else history.push('/accounting-documents');
              }}
            >
              {mode === 'modifyingAD' ? '儲存' : '新增'}
            </antd.Button>
          </div>
        </div>
        {mode === 'addADFromCommon' ? (
          <label>
            常用傳票名稱:
            <Input
              value={meta.name}
              className="w-40 ml-2"
              onChange={(e) => setMeta({ ...meta, name: e.target.value })}
            />
          </label>
        ) : null}
        <div className="flex justify-between text-lg">
          <span className="flex gap-2">
            <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
              日期:
            </label>
            <DatePicker
              onChange={(val) => {
                setMeta((meta) => ({
                  ...meta,
                  date: val ? val.format(dateFormat) : undefined,
                }));
              }}
              value={meta.date ? moment(meta.date, dateFormat) : undefined}
            />
          </span>
          {mode === 'modifyingAD' ? <span>傳票編號: {meta.code}</span> : null}
        </div>
      </div>

      <Form
        preserve={false}
        form={form}
        component={false}
        onValuesChange={async (
          e: { [key: string]: string | number },
          allValues,
        ) => {
          console.log(e, allValues);
          const key = Object.keys(e)[0];
          const keyStr = key.split('-')[0];
          const keyIdx = parseInt(key.split('-')[1]);
          const keyValue = e[key];

          if (keyStr === 'lender' || keyStr === 'debtor') {
            if (keyValue < 0) {
              form.setFieldsValue({ [key]: 0 });
              return;
            }
            if (keyStr === 'lender') {
              form.setFieldsValue({ [`debtor-${keyIdx}`]: 0 });
              setDataSource((dataSource) =>
                dataSource.map((row, i) => {
                  return i === keyIdx ? { ...row, debtor: 0 } : row;
                }),
              );
            } else {
              form.setFieldsValue({ [`lender-${keyIdx}`]: 0 });
              setDataSource((dataSource) =>
                dataSource.map((row, i) => {
                  return i === keyIdx ? { ...row, lender: 0 } : row;
                }),
              );
            }
          }

          setDataSource((dataSource) =>
            dataSource.map((row, i) => {
              console.log({ ...row, [keyStr]: keyValue });
              return i === keyIdx ? { ...row, [keyStr]: keyValue } : row;
            }),
          );

          const rowIndex = parseInt(Object.keys(e)[0].split('-')[1]);
          if (rowIndex === dataSource.length - 1 && e[Object.keys(e)[0]]) {
            setDataSource((dataSource) => [
              ...dataSource,
              { key: randomLessThanOne() },
            ]);
          }

          const lastRowValues: string[] = [];
          const secondLastRowValues: string[] = [];
          Object.entries(allValues).forEach(([k, v]) => {
            if (parseInt(k.split('-')[1]) === dataSource.length - 1)
              lastRowValues.push(v as string);
            else if (parseInt(k.split('-')[1]) === dataSource.length - 2)
              secondLastRowValues.push(v as string);
          });

          let isLastRowEmpty = false;
          let isSecondLastRowEmpty = false;
          isLastRowEmpty = lastRowValues.every((value) => !value);
          isSecondLastRowEmpty = secondLastRowValues.every((value) => !value);

          if (isLastRowEmpty && isSecondLastRowEmpty)
            setDataSource((dataSource) =>
              dataSource.slice(0, dataSource.length - 1),
            );

          if (keyStr === 'code') {
            const selectedOption = gradeOptions.find(
              (option: Grade) => option.code === keyValue,
            );
            console.log(selectedOption);
            setDataSource((dataSource) =>
              dataSource.map((row, i) =>
                i === keyIdx
                  ? {
                      ...row,
                      codeName: selectedOption?.name,
                      codeId: selectedOption?.key,
                      isSubGrade: selectedOption?.isSubGrade,
                    }
                  : row,
              ),
            );
          }

          if (keyStr === 'vendor') {
            const selectedOption = vendorOptions.find(
              (option) => option.shortName === keyValue,
            );
            console.log(selectedOption);
            setDataSource((dataSource) =>
              dataSource.map((row, i) =>
                i === keyIdx
                  ? {
                      ...row,
                      vendorId: selectedOption?.key,
                    }
                  : row,
              ),
            );
          }
        }}
      >
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
        <div className="text-gray-900 py-4 border border-gray-100 bg-[#fafafa] flex font-medium">
          <div className="w-[70%] pl-4">合計</div>
          <div className="w-[10%] pl-7">{totalObj.lendorTotal}</div>
          <div className="w-[10%] pl-7">{totalObj.debtorTotal}</div>
        </div>
      </Form>

      <AddADToCommon
        visible={showCommonlyUsedModal}
        handleOk={() => setShowCommonlyUsedModal(false)}
        handleCancel={() => setShowCommonlyUsedModal(false)}
        data={dataSource.slice(0, dataSource.length - 1)}
      />
    </antd.Spin>
  );
};

export { AccountingDocumentsDetailsPage };
