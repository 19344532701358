import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import * as antd from 'antd';
import qs from 'qs';

import { AppContext } from '../AppContext';
import { AEEntryOrder } from '../modals/AEEntryOrder';
import { DangerButton } from '../components/DangerButton';

const EntryOrderPage = () => {
  const appCtx = React.useContext(AppContext);

  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<EntryOrder[]>([]); //coulmns data
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);
  const pageSize = 10;

  const init = async (page = currentPage, title?: string) => {
    setSpinning(true);
    const qstring = {
      sort: 'date:asc',
      pagination: { page, pageSize },
    };
    const data = await appCtx.fetch(
      'get',
      '/api/entry-orders?' + qs.stringify(qstring),
    );
    if (data) {
      const temp = data.data.map((item: any) => {
        return {
          key: item.id,
          id: item.id,
          ...item.attributes,
        };
      });

      setDataSource(temp);
      setTotal(data.meta.pagination.total);
      setCurrentPage(data.meta.pagination.page);
      setSpinning(false);
    }
  };

  React.useEffect(() => {
    appCtx.redirect();
  }, [appCtx]);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appCtx.jwt]);

  interface EntryOrder {
    name: string;
    date: string;
    kind: string;
    contactName: string;
    phone: string;
    count: number;
    note: string;
    createdAt: string;
  }

  const deleteEntryOrder = async (id: number) => {
    await appCtx.fetch('delete', `/api/entry-orders/${id}`);
    init();
  };

  const columns: ColumnsType<EntryOrder> = [
    { title: '日期', align: 'center', dataIndex: 'date' },
    { title: '團體名稱', align: 'center', dataIndex: 'name' },
    { title: '類型', align: 'center', dataIndex: 'kind' },
    { title: '聯絡人', align: 'center', dataIndex: 'contactName' },
    { title: '電話', align: 'center', dataIndex: 'phone' },
    { title: '人數', align: 'center', dataIndex: 'count' },
    {
      title: '操作',
      align: 'center',
      width: 180,
      render: (item) => (
        <>
          <antd.Button
            className="mr-3"
            type="primary"
            onClick={() =>
              appCtx.setModal(<AEEntryOrder item={item} onSuccess={init} />)
            }
          >
            編輯
          </antd.Button>
          <DangerButton
            title="刪除"
            message="確定要刪除?"
            onClick={() => deleteEntryOrder(item.id)}
          />
        </>
      ),
    },
  ];

  return (
    <antd.Spin spinning={spinning}>
      <div className="flex mb-2">
        <antd.Button
          type="primary"
          onClick={() => appCtx.setModal(<AEEntryOrder onSuccess={init} />)}
        >
          入園訂單建立
        </antd.Button>
      </div>
      <antd.Table
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={columns}
        pagination={
          total <= pageSize
            ? false
            : {
                current: currentPage,
                pageSize: pageSize,
                total: total,
                onChange: (page) => init(page),
              }
        }
        expandable={{
          expandedRowRender: (record) => (
            <>
              <>備註: </>
              <antd.Input.TextArea rows={4} value={record.note} />
            </>
          ),
        }}
      />
    </antd.Spin>
  );
};

export { EntryOrderPage };
