import React, { useCallback, useState } from 'react';
import qs from 'qs';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Table, Form, Input, DatePicker, Spin } from 'antd';

import { AccountingDocumentDetail } from './types';
import { AppContext } from '../../AppContext';
import { useForm } from 'antd/lib/form/Form';

interface ParamsProps {
  id: string;
}

const VendorDocumentDetailsPage = () => {
  const appCtx = React.useContext(AppContext);
  const [gradeForm] = useForm();
  const [dateForm] = useForm();
  const { id } = useParams<ParamsProps>();
  const history = useHistory();

  const [dataSource, setDataSource] = useState<AccountingDocumentDetail[]>([]);
  const [spinning, setSpinning] = useState(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const pageSize = 10;

  const init = useCallback(
    async (page = currentPage, search: any = {}) => {
      console.log(search);
      let filters;
      if (search.code)
        filters = {
          $and: [
            {
              $or: [
                { grade: { code: { $containsi: search.code } } },
                { sub_grade: { code: { $containsi: search.code } } },
              ],
            },
            {
              vendor: {
                id: { $eq: id },
              },
            },
          ],
        };
      else if (search.date)
        filters = {
          $and: [
            {
              $and: [
                {
                  subpoena: {
                    date: { $gte: search.date.start.format('YYYY-MM-DD') },
                  },
                },
                {
                  subpoena: {
                    date: { $lte: search.date.end.format('YYYY-MM-DD') },
                  },
                },
              ],
            },
            {
              vendor: {
                id: { $eq: id },
              },
            },
          ],
        };
      else {
        filters = {
          vendor: {
            id: { $eq: id },
          },
        };
      }

      setSpinning(true);
      const pagination = {
        pagination: { page, pageSize },
        populate: ['vendor', 'grade', 'sub_grade'],
        filters,
      };

      const result = await appCtx.fetch(
        'get',
        '/api/subpoena-details?' + qs.stringify(pagination),
      );
      if (result) {
        const dataWithKeys = result.data.map((item: any) => ({
          key: item.id,
          code: item.attributes.grade?.data
            ? item.attributes.grade.data.attributes.code
            : item.attributes.sub_grade.data.attributes.code,
          codeName: item.attributes.grade?.data
            ? item.attributes.grade.data.attributes.name
            : item.attributes.sub_grade.data.attributes.name,
          vendor: item.attributes.vendor.data.attributes.name,
          summary: item.attributes.summary,
          lender: item.attributes.lender,
          debtor: item.attributes.debit,
        }));
        setDataSource(dataWithKeys);
        setTotal(result.meta.pagination.total);
      }
      setSpinning(false);
    },
    [appCtx, currentPage, id],
  );

  React.useEffect(() => {
    appCtx.redirect();
  }, [appCtx]);

  React.useEffect(() => {
    if (appCtx.jwt) {
      init();
    }
  }, [appCtx.jwt, init]);

  const columns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'index',
      editable: false,
      render: (text: any, record: AccountingDocumentDetail, index: number) => (
        <div className="text-center">{index + 1}</div>
      ),
    },
    {
      title: '代碼',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '科目',
      dataIndex: 'codeName',
      key: 'codeName',
    },
    {
      title: '供應商',
      dataIndex: 'vendor',
      key: 'vendor',
    },
    {
      title: '摘要',
      dataIndex: 'summary',
      key: 'summary',
    },
    {
      title: '借方',
      dataIndex: 'lender',
      key: 'lender',
    },
    {
      title: '貸方',
      dataIndex: 'debtor',
      key: 'debtor',
    },
  ];

  return (
    <Spin spinning={spinning}>
      <div className="grid gap-4 mb-3 justify-items-start">
        <Button className="px-large" onClick={() => history.goBack()}>
          返回
        </Button>
        <Form
          layout="inline"
          onFinish={(values) => {
            init(1, { code: values.code });
          }}
          form={gradeForm}
          className="w-full"
        >
          <Form.Item label="科目查詢" name="code">
            <Input placeholder="請輸入科目編號" className="w-44" />
          </Form.Item>
          <Form.Item>
            <Button className="px-4" htmlType="submit">
              查詢
            </Button>
          </Form.Item>
        </Form>
        <Form
          form={dateForm}
          onFinish={(values) => {
            if (values.startDate && values.endDate)
              init(1, {
                date: {
                  start: values.startDate,
                  end: values.endDate,
                },
              });
            else init(1);
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            setTimeout(() => {
              const arr = errorFields.map((field) => ({
                name: field.name,
                errors: [],
              }));
              dateForm.setFields(arr);
            }, 2000);
          }}
        >
          <div className="flex justify-center gap-4">
            <Form.Item label="日期區間" name="startDate" className="mb-0">
              <DatePicker
                // onChange={onStartDateChange}
                // disabledDate={disabledStartDate}
                placeholder="起始日期"
                className="w-44"
              />
            </Form.Item>
            <Form.Item name="endDate" className="mb-0">
              <DatePicker
                // disabledDate={disabledEndDate}
                // onChange={onEndDateChange}
                placeholder="結束日期"
                className="w-44"
              />
            </Form.Item>
            <Button className="block mx-auto px-large" htmlType="submit">
              搜尋
            </Button>
          </div>
        </Form>
      </div>

      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: (page) => init(page),
        }}
      />
    </Spin>
  );
};

export { VendorDocumentDetailsPage };
