import { Modal, Button, Form, Input } from 'antd';

import { emptySubGrade, SubGrade } from '../pages/Accounting/types';

interface AddOrUpdateSubGradeProps {
  visible: boolean;
  handleOk: (values: SubGrade, subGradeId?: number) => void;
  handleCancel: () => void;
  modalLoading: boolean;
  gradeCode: string;
  subGrade: SubGrade | null;
}

const AddOrUpdateSubGrade = ({
  visible,
  handleOk,
  handleCancel,
  modalLoading,
  gradeCode,
  subGrade,
}: AddOrUpdateSubGradeProps) => {
  const [form] = Form.useForm();
  const initObj = subGrade
    ? {
        initialValues: {
          ...subGrade,
          code: subGrade.code.replace(gradeCode, ''),
        },
      }
    : { initialValues: { ...emptySubGrade } };

  const onConfirmModal = () => {
    form
      .validateFields()
      .then((values) => {
        handleOk({ ...values, code: gradeCode + values.code }, subGrade?.key);
      })
      .catch(({ values, errorFields, outOfDate }) => {
        setTimeout(() => {
          const arr = errorFields.map((field: { name: string }) => ({
            name: field.name,
            errors: [],
          }));
          form.setFields(arr);
        }, 2000);
      });
  };
  return (
    <Modal
      visible={visible}
      title={
        <h1 className="text-lg text-center">
          {subGrade ? '修改子科目' : '新增子科目'}
        </h1>
      }
      //   okText="Create"
      //   cancelText="Cancel"
      footer={[
        <div className="flex justify-center gap-6" key={1}>
          <Button key="back" onClick={handleCancel} className="px-large">
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            className="px-large"
            onClick={onConfirmModal}
            loading={modalLoading}
          >
            確定
          </Button>
        </div>,
      ]}
      onCancel={handleCancel}
      onOk={onConfirmModal}
      closable={false}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        requiredMark={true}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        // initialValues={{ ...vendor }}
        {...initObj}
      >
        <Form.Item
          name="code"
          label={`子科目代號:${gradeCode}`}
          colon={false}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          rules={[
            {
              required: true,
              message: '請輸入子科目代號',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入子科目代號" />
        </Form.Item>
        <Form.Item
          name="name"
          label="子科目名稱"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          rules={[
            {
              required: true,
              message: '請輸入子科目名稱',
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="輸入子科目名稱" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrUpdateSubGrade;
